import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  Typography,
  LinearProgress,
  Box,
  IconButton,
  Pagination,
} from "@mui/material";
import { Visibility } from "@mui/icons-material";
import { Link } from "react-router-dom";

const CategoryTable = ({ item, showUsage }) => {
  const [page, setPage] = useState(1);
  const itemsPerPage = 5;

  // Calculate total pages
  const totalPages = Math.ceil(item.items.length / itemsPerPage);

  // Function to handle page change
  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const calculateUsagePercentage = (totalStock = 0, remainingInStock = 0) => {
    if (
      typeof totalStock !== "number" ||
      typeof remainingInStock !== "number" ||
      totalStock === 0
    )
      return 0;
    const usedStock = totalStock - remainingInStock;
    return (usedStock / totalStock) * 100;
  };

  const calculateRemainingPercentage = (
    totalStock = 0,
    remainingInStock = 0
  ) => {
    if (
      typeof totalStock !== "number" ||
      typeof remainingInStock !== "number" ||
      totalStock === 0
    )
      return 0;
    return (remainingInStock / totalStock) * 100;
  };

  const getProgressColor = (percentage) => {
    if (percentage >= 80) return "green";
    if (percentage >= 60) return "yellow";
    if (percentage >= 40) return "orange";
    if (percentage >= 15) return "deeppink";
    return "red";
  };

  // Slice the items for the current page
  const displayedItems = item.items.slice(
    (page - 1) * itemsPerPage,
    page * itemsPerPage
  );

  const hasValidCategory = item.category && item.category.trim() !== "";
  const hasValidDependencies = displayedItems.some(
    (itemDetail) => itemDetail?.proDependency?.length > 0
  );

  if (!hasValidCategory && !hasValidDependencies) {
    return null;
  }

  return (
    <Paper elevation={3} sx={{ marginBottom: 4, padding: 2 }}>
      <Typography variant="h5" component="h2" gutterBottom>
        {hasValidCategory ? item.category : "Components"}{" "}
        {/* Fallback if category is empty */}
      </Typography>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>In Stock</TableCell>
              <TableCell>Stock Type</TableCell>
              <TableCell>Alert</TableCell>
              <TableCell>Supported Types</TableCell>
              <TableCell>Usage</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {displayedItems.map((itemDetail) => {
              const percentage = showUsage
                ? calculateUsagePercentage(
                    itemDetail.totalStock,
                    itemDetail.remainingInStock
                  )
                : calculateRemainingPercentage(
                    itemDetail.totalStock,
                    itemDetail.remainingInStock
                  );

              return (
                <TableRow key={itemDetail.name}>
                  <TableCell>{itemDetail.name}</TableCell>
                  <TableCell>{itemDetail.remainingInStock}</TableCell>
                  <TableCell>{itemDetail.stockType}</TableCell>
                  <TableCell>
                    <Box
                      component="span"
                      sx={{
                        backgroundColor:
                          itemDetail.remainingInStock <= itemDetail.stockAlert
                            ? "error.main"
                            : "success.main",
                        color: "white",
                        borderRadius: 1,
                        padding: "2px 8px",
                      }}
                    >
                      {itemDetail.remainingInStock <= itemDetail.stockAlert
                        ? itemDetail.stockAlert
                        : "Safe"}
                    </Box>
                  </TableCell>
                  <TableCell>
                    {itemDetail.proDependency &&
                    itemDetail.proDependency.length > 0
                      ? itemDetail.proDependency.join(", ")
                      : "N/A"}
                  </TableCell>
                  <TableCell>
                    <LinearProgress
                      variant="determinate"
                      value={percentage}
                      sx={{
                        height: 16,
                        borderRadius: 1,
                        "& .MuiLinearProgress-bar": {
                          backgroundColor: getProgressColor(percentage),
                        },
                      }}
                    />
                    <Box sx={{ textAlign: "center", mt: 1 }}>
                      {Math.round(percentage)}%{" "}
                      {showUsage ? "Used" : "Remaining"}
                    </Box>
                  </TableCell>
                  <TableCell>
                    <IconButton
                      component={Link}
                      to={`/inventory/view-item/${itemDetail.id}`}
                      color="primary"
                    >
                      <Visibility />
                    </IconButton>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Pagination */}
      <Box display="flex" justifyContent="center" mt={2}>
        <Pagination
          count={totalPages}
          page={page}
          onChange={handlePageChange}
          variant="outlined"
          shape="rounded"
          color="primary"
        />
      </Box>
    </Paper>
  );
};

CategoryTable.propTypes = {
  item: PropTypes.shape({
    category: PropTypes.string.isRequired,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        remainingInStock: PropTypes.number.isRequired,
        totalStock: PropTypes.number.isRequired,
        category: PropTypes.string.isRequired,
        proDependency: PropTypes.arrayOf(PropTypes.string).isRequired,
        stockAlert: PropTypes.number.isRequired,
        stockType: PropTypes.string.isRequired,
        id: PropTypes.string.isRequired,
      })
    ).isRequired,
  }).isRequired,
  showUsage: PropTypes.bool,
};

export default CategoryTable;
