export const FETCH_ITEMS_REQUEST = 'FETCH_ITEMS_REQUEST';
export const FETCH_ITEMS_SUCCESS = 'FETCH_ITEMS_SUCCESS';
export const FETCH_ITEMS_FAILURE = 'FETCH_ITEMS_FAILURE';


export const FETCH_CALCULATIONS_REQUEST = 'FETCH_CALCULATIONS_REQUEST';
export const FETCH_CALCULATIONS_SUCCESS = 'FETCH_CALCULATIONS_SUCCESS';
export const FETCH_CALCULATIONS_FAILURE = 'FETCH_CALCULATIONS_FAILURE';

export const FETCH_FABRICS_REQUEST = 'FETCH_FABRICS_REQUEST';
export const FETCH_FABRICS_SUCCESS = 'FETCH_FABRICS_SUCCESS';
export const FETCH_FABRICS_FAILURE = 'FETCH_FABRICS_FAILURE';

export const INV_ITEMS_GET_REQUEST = 'INV_ITEMS_GET_REQUEST';
export const INV_ITEMS_GET_SUCCESS = 'INV_ITEMS_GET_SUCCESS';
export const INV_ITEMS_GET_FAIL = 'INV_ITEMS_GET_FAIL';

export const FETCH_LIFT_STYLE_REQUEST = 'FETCH_LIFT_STYLE_REQUEST';
export const FETCH_LIFT_STYLE_SUCCESS = 'FETCH_LIFT_STYLE_SUCCESS';
export const FETCH_LIFT_STYLE_FAILURE = 'FETCH_LIFT_STYLE_FAILURE';
