import React, { useEffect, useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { API_URI } from "../../../constants/GlobalConstants";
import Loader from "../../../components/layout/Loader";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const DealerSalesData = () => {
  const [salesData, setSalesData] = useState({});
  const [loading, setLoading] = useState(true);
  const [monthsAgo, setMonthsAgo] = useState(0);

  const userLogin = useSelector((state) => state.userSignIn);
  const { userInfo } = userLogin;

  useEffect(() => {
    const fetchSalesData = async () => {
      try {
        const config = {
          headers: {
            Authorization: `Bearer ${userInfo.token}`,
          },
        };

        const { data } = await axios.get(
          `${API_URI}/api/analytics/dealer-sales?monthsAgo=${monthsAgo}`,
          config
        );

        const dealers = [...new Set(data.map((order) => order._id.dealerName))];
        const months = [
          ...new Set(
            data.map((order) => `${order._id.month}-${order._id.year}`)
          ),
        ].sort();

        const datasets = dealers.map((dealer) => {
          return {
            label: dealer,
            data: months.map((month) => {
              const matchingOrder = data.find(
                (order) =>
                  `${order._id.month}-${order._id.year}` === month &&
                  order._id.dealerName === dealer
              );
              return matchingOrder ? matchingOrder.totalPayment : 0;
            }),
            backgroundColor: getRandomColor(),
          };
        });

        setSalesData({
          labels: months.map((month) => formatMonthYear(month)),
          datasets: datasets,
        });

        setLoading(false);
      } catch (error) {
        console.error("Error fetching dealer sales data", error);
      }
    };

    fetchSalesData();
  }, [userInfo.token, monthsAgo]);

  const getRandomColor = () => {
    const letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  const formatMonthYear = (monthYear) => {
    const [month, year] = monthYear.split("-");
    const date = new Date(year, month - 1);
    return date.toLocaleString("default", { month: "long", year: "numeric" });
  };

  const handlePreviousMonths = () => {
    setMonthsAgo(monthsAgo + 6);
  };

  const handleNextMonths = () => {
    if (monthsAgo >= 6) {
      setMonthsAgo(monthsAgo - 6);
    }
  };

  if (loading) return <Loader />;

  return (
    <div>
      <h4 className="mb-4">Dealer Sales Comparison (Last 6 Months)</h4>
      <Bar
        data={salesData}
        options={{
          responsive: true,
          plugins: {
            legend: {
              position: "top",
            },
            title: {
              display: true,
              text: "Orders per Dealer by Month",
            },
            tooltip: {
              callbacks: {
                label: function (tooltipItem) {
                  const dealerName = tooltipItem.dataset.label;
                  const totalPayment = tooltipItem.raw.toLocaleString();
                  return `${dealerName}: $${totalPayment}`; 
                },
              },
            },
          },
          scales: {
            y: {
              ticks: {
                callback: function (value) {
                  return `$${value.toLocaleString()}`;
                },
              },
            },
          },
        }}
      />
      <button onClick={handlePreviousMonths}>Previous 6 Months</button>
      <button onClick={handleNextMonths} disabled={monthsAgo === 0}>
        Next 6 Months
      </button>
    </div>
  );
};

export default DealerSalesData;
