import React, { useEffect, useRef } from "react";
import { useState } from "react";
import Badge from "../layout/Badge";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import Loader from "../layout/Loader";
import fileDownload from "js-file-download";
import { createDealerOrderAction } from "../../redux/actions/OrderAction";
import { ListDealerAction } from "../../redux/actions/DealerAction";
import { getProductionData } from "./functions/OrderFunctions";
//MUI Dropdown Action
import ContentCutIcon from "@mui/icons-material/ContentCut";
import AddIcon from "@mui/icons-material/Add";
import SubdirectoryArrowRightIcon from "@mui/icons-material/SubdirectoryArrowRight";
import { NotificationBadge } from "./ui/NotificationBadge";
import OrderOverviewUI from "./OrderComp/tables/OrderOverviewUI";
import { toggleFullScreen } from "../layout/functions/layout";
import { productTypeOptions } from "./functions/ComponentOptions";
import InputTableHeader from "./reusables/InputTableHeader";
import TableInputs from "./reusables/TableInputs";
import CustomKeyboard from "./functions/CustomKeyboard";
import { fetchSettingsAction } from "../../redux/actions/UserSettingsActions";
import {
  fetchCalculations,
  getAllInventoryItems,
  getFabricItems,
  getInvItemsByCat,
  getLiftStyleItems,
} from "../../redux/actions/InventoryAction";
import { recalculateOrders } from "./reusables/RecalculateOrder";
import { invParents } from "../../constants/GlobalConstants";
import OrderDetailsForm from "./orderForm/OrderDetailsForm";
import InputSettingsDropdown from "./orderForm/InputSettings";
import MaterialOrdersTable from "./reusables/MaterialTable";
import { fetchMatrixByProductType } from "../../redux/actions/MatrixAction";

const OrderCreate = ({ nav }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const userLogin = useSelector((state) => state.userSignIn);
  const { userInfo } = userLogin;
  const listDealer = useSelector((state) => state.listDealer);
  const { loading: loadingClient, dealer, success } = listDealer;
  const createOrder = useSelector((state) => state.orderDealerCreate);
  const inventoryItems = useSelector((state) => state.getInvItemsByCat);
  const { data: inventoryData } = inventoryItems;
  const items = inventoryData?.[0]?.items || [];
  const fabricItemsState = useSelector((state) => state.fabricItems);
  const {
    loading: fabricLoading,
    error: fabricError,
    fabrics,
  } = fabricItemsState;
  const Fabrics = fabrics?.[0]?.items || [];
  const { liftStyleItems } = useSelector((state) => state.liftSyleItems);
  const LiftStyle = liftStyleItems?.[0]?.items || [];
  

  const createInvItem = useSelector((state) => state.createInvItemReducer);
  const { success: inventoryCreateSuccess } = createInvItem;
  const {
    loading: loadingCreateOrder,
    data,
    success: successOrder,
  } = createOrder;
  const matrixState = useSelector((state) => state.matrix);
  const { loading, matrix, error } = matrixState;
  
  const [showCustomFabricModal, setShowCustomFabricModal] = useState(false);
  const [showCustomLiftStyleModal, setShowCustomLiftStyleModal] = useState(false);


  const [errToggle, setErrToggle] = useState({});
  // const [dropdownToggle, setDropdownToggle] = useState(null);

  const [orderDetails, setOrderDetails] = useState({
    dealerName: "",
    productTag: "",
    productType: "",
    productMaterial: "",
    headrail: "",
    liftStyle: "",
    orderRemark: "",
  });
  const [orderFactor, setOrderFactor] = useState(0);
  const [customCost, setCustomCost] = useState(0);
  const [showAddMaterialModal, setShowAddMaterialModal] = useState(false);
  const [activeRowIndex, setActiveRowIndex] = useState(null);
  const [insertion, setInsertion] = useState({ val: null, trigger: false });

  const settings = useSelector((state) => state.userSettings);
  const { settings: settingsData } = settings;
  const [order, setOrder] = useState([]);

  const [showSettingsModal, setShowSettingsModal] = useState(false);
  const [sleeveOption, setSleeveOption] = useState("");

  //keyboard states and refs
  const [isKeyboardVisible, setIsKeyboardVisible] = useState(false);
  const [isReadOnly, setIsReadOnly] = useState(true);
  const [focusedIndex, setFocusedIndex] = useState(null);
  const { calculations } = useSelector((state) => state.inventoryCalculation);
  const [deduction, setDeduction] = useState({ val: null, trigger: false });
  const [addition, setAddition] = useState({ val: null, trigger: false });

  const [showAdditionInput, setShowAdditionInput] = useState(false);
  const [showDeductionInput, setShowDeductionInput] = useState(false);
  const [selectedMaterialId, setSelectedMaterialId] = useState("");
  const [category, setCategory] = useState("");
  const [materialOrders, setMaterialOrders] = useState([
    { category: "", material: "", customCost: "" },
  ]);

  const inputRefs = useRef([]);
  const tableContainerRef = useRef(null);

  useEffect(() => {
    if (settingsData) {
      const initialOrder = {
        width: "",
        height: "",
        factor: 0,
      };

      if (settingsData.showControl) {
        initialOrder.control = "right";
      }

      if (settingsData.showLocation) {
        initialOrder.location = "";
      }

      if (settingsData.showMountLift) {
        initialOrder.lift = "corded";
      }

      if (settingsData.showRemark) {
        initialOrder.remark = "";
      }
      if (settingsData.showTile) {
        initialOrder.tile = "";
      }

      setOrder([initialOrder]);
    }
  }, [settingsData]);

  useEffect(() => {
    dispatch(fetchSettingsAction(userInfo.token));
  }, [dispatch]);
  const headrailConfig = items?.find(
    (item) => item.id === orderDetails?.headrail
  );
  useEffect(() => {
    if (orderDetails.productType && headrailConfig) {
      dispatch(
        fetchCalculations(
          orderDetails.productType,
          headrailConfig?.id,
          userInfo.token
        )
      );
    }
  }, [userInfo.token, dispatch, orderDetails.productType, headrailConfig]);

  useEffect(() => {
    const handleResize = () => {
      if (window.matchMedia("(max-width: 1000px)").matches) {
        setIsReadOnly(true);
        setIsKeyboardVisible(true);
      } else {
        setIsReadOnly(false);
        setIsKeyboardVisible(false);
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleSettingsClick = () => {
    setShowSettingsModal(true);
  };

  const handleCloseModal = () => {
    setShowSettingsModal(false);
  };
  //keyboard functions
  const handleKeyPress = (key) => {
    if (focusedIndex !== null) {
      const { row, field } = focusedIndex;
      const currentValue = order[row][field];

      const keyString = String(key);

      if (keyString.includes("/")) {
        const parts = currentValue.split(" ");
        if (parts.length > 1) {
          parts[parts.length - 1] = keyString;
        } else {
          parts.push(keyString);
        }
        const newValue = parts.join(" ");
        handleInputChange(row, field, newValue);
      } else {
        const newValue = currentValue + keyString;
        handleInputChange(row, field, newValue);
      }
    }
  };

  const handleNext = () => {
    if (focusedIndex !== null) {
      const { row, field } = focusedIndex;
      if (field === "width") {
        setFocusedIndex({ row, field: "height" });
      } else if (field === "height" && row < order.length - 1) {
        setFocusedIndex({ row: row + 1, field: "width" });
      }
    }
  };

  const handlePrevious = () => {
    if (focusedIndex !== null) {
      const { row, field } = focusedIndex;
      if (field === "height" && row > 0) {
        setFocusedIndex({ row: row - 1, field: "width" });
      } else if (field === "width") {
        setFocusedIndex({ row, field: "height" });
      }
    }
  };

  const handleEnter = () => {
    if (focusedIndex !== null) {
      const { row } = focusedIndex;
      if (row < order.length - 1) {
        setFocusedIndex({ row: row + 1, field: "width" });
      } else {
        setOrder([
          ...order,
          { width: "", height: "", sqft: 0, remark: "", location: "" },
        ]);
        setFocusedIndex({ row: row + 1, field: "width" });
      }
    }
  };

  const handleClear = () => {
    if (focusedIndex !== null) {
      const { row, field } = focusedIndex;
      handleInputChange(row, field, "");
    }
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setOrderDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  const handleFocus = (row, field) => {
    if (field === "width" || field === "height") {
      setFocusedIndex({ row, field });
      if (window.matchMedia("(max-width: 1000px)").matches) {
        setIsKeyboardVisible(true);
        setActiveRowIndex(row);
      }
    } else {
      setIsKeyboardVisible(false);
    }
  };
  const handleCloseKeyboard = () => {
    setIsKeyboardVisible(false);
  };

  const addRow = (index) => {
    const newOrder = [...order];
    newOrder.splice(index + 1, 0, {
      width: "",
      height: "",
      factor: 0,
      location: "",
      remark: "",
    });
    setOrder(newOrder);
  };

  const handleInputChange = (index, field, value) => {
    // Create a shallow copy of the order array
    const updatedOrder = [...order];

    // Get the current row
    const currentRow = updatedOrder[index];

    updatedOrder[index] = { ...currentRow, [field]: value };

    setOrder(updatedOrder);

    const lastRow = updatedOrder[updatedOrder.length - 1];
    const isLastRowFilled = lastRow.width && lastRow.height;

    // Add an empty row if the last row is filled
    if (isLastRowFilled) {
      updatedOrder.push({
        width: "",
        height: "",
        control: "",
        lift: "",
        mount: "",
        remark: "",
        location: "",
        tile: "",
        deduction: "",
        addition: "",
      });

      setOrder(updatedOrder);
    }
  };

  // Function to handle recalculation when input field loses focus
  const handleRecalculate = (index) => {
    const updatedOrder = [...order];
    const currentRow = updatedOrder[index];

    // Check if deduction or addition has changed
    const deductionValue = deduction.trigger ? deduction.val : null;
    const additionValue = addition.trigger ? addition.val : null;

    const prevDeduction = currentRow.deduction;
    const prevAddition = currentRow.addition;

    // Recalculate only if deduction or addition has changed
    if (deductionValue !== prevDeduction || additionValue !== prevAddition) {
      // Perform recalculation for width/height with the current values
      const recalculatedRow = getProductionData(
        orderDetails.productType,
        currentRow.width,
        currentRow.height,
        currentRow.control,
        currentRow.lift,
        currentRow.mount,
        currentRow.remark,
        currentRow.location,
        calculations[0],
        deductionValue,
        additionValue,
        currentRow.tile,
        matrix
      );

      // Update the row with recalculated values
      updatedOrder[index] = { ...currentRow, ...recalculatedRow };

      // Update the order state with recalculated values
      setOrder(updatedOrder);
    }
  };

  useEffect(() => {
    if (calculations[0]) {
      recalculateOrders(
        order,
        orderDetails.productType,
        calculations[0],
        setOrder,
        deduction.val,
        addition.val,
        matrix
      );
    }
  }, [calculations[0]]);

  //----- Submitting the order to the DB → 💽
  const handleSubmitOrder = async () => {
    let dealer = orderDetails.dealerName.split("_");

    const filteredOrder = order.filter((row) => {
      const hasWidth = row.width && row.width.trim() !== "";
      const hasHeight = row.height && row.height.trim() !== "";

      return row.id !== -1 && hasWidth && hasHeight;
    });

    const val = {
      dealerName: dealer[0],
      dealerId: dealer[1],
      productTag: orderDetails.productTag,
      productType: orderDetails.productType,
      productMaterial: orderDetails.productMaterial,
      liftStyle: orderDetails.liftStyle,
      orderRemark: orderDetails.orderRemark,
      order: filteredOrder,
      orderFactor,
      headrail: orderDetails.headrail,
      sleeveOption: sleeveOption,
      selectedMaterialId,
      materialOrders,
    };

    await dispatch(createDealerOrderAction(val, userInfo.token));
  };

  useEffect(() => {
    if (!userInfo) {
      navigate("/sign-in");
    }
    window.scrollTo(0, 0);
    toggleFullScreen((nav = { nav }));
  }, [userInfo, navigate]);

  useEffect(() => {
    // window.scrollTo(0, document.body.scrollHeight);
    const validOrders = order.filter(({ factor, width, height }) => {
      return !isNaN(factor) && Number(factor) > 0 && width && height;
    });

    const totalFactor = validOrders.reduce((total, { factor = 0 }) => {
      return total + (isNaN(factor) ? 0 : Number(factor));
    }, 0);

    setOrderFactor(totalFactor);
  }, [order]);

  useEffect(() => {
    dispatch(ListDealerAction(userInfo.token));
  }, []);

  useEffect(() => {
    let dealer = orderDetails.dealerName.split("_");
    if (data) {
      fileDownload(
        data,
        `${dealer[0]}-${orderDetails.productType}-${orderDetails.productTag}.pdf`
      );
    }
  }, [data]);

  const renderHeadrailOptions = (productType) => {
    if (!items) return null;

    return items.map((item, index) => {
      if (productType && !item.proDependency.includes(productType)) {
        return null;
      }
      return (
        <option key={index} value={item.id}>
          {item.name}
        </option>
      );
    });
  };

  const deleteRow = (index) => {
    const newOrder = order.filter((_, i) => i !== index);
    setOrder(newOrder);
  };

  useEffect(() => {
    if (focusedIndex !== null && inputRefs.current[focusedIndex.row]) {
      const input = inputRefs.current[focusedIndex.row][focusedIndex.field];
      if (input) {
        input.focus();
        input.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }
    }
  }, [focusedIndex]);

  useEffect(() => {
    if (userInfo?.token && orderDetails.productType) {
      if (orderDetails.productType === "Materials" && category) {
        dispatch(getAllInventoryItems(userInfo.token, category));
      } else {
        dispatch(
          getInvItemsByCat("Headrail", userInfo.token, orderDetails.productType)
        );
      }
      dispatch(
        getFabricItems("Fabric", userInfo.token, orderDetails.productType)
      );
      dispatch(
        getLiftStyleItems(
          "Lift_hardware",
          userInfo.token,
          orderDetails.productType
        )
      );
      dispatch(fetchMatrixByProductType(orderDetails.productType,userInfo.token))
    }
  }, [
    userInfo.token,
    dispatch,
    orderDetails.productType,
    inventoryCreateSuccess,
    category,
  ]);

  const handleSleeveChange = (e) => {
    setSleeveOption(e.target.value);
  };

  const fabricMapping = Fabrics.reduce((acc, fabric) => {
    acc[fabric.id] = fabric.name;
    return acc;
  }, {});

  const handleAdditionClick = () => {
    setShowAdditionInput(true);
    setShowDeductionInput(false);
  };

  const handleDeductionClick = () => {
    setShowDeductionInput(true);
    setShowAdditionInput(false);
  };

  const handleCancel = () => {
    setShowAdditionInput(false);
    setShowDeductionInput(false);
    // Reset the deduction and addition values
    setAddition({ val: null, trigger: false });
    setDeduction({ val: null, trigger: false });
  };
  const handleFractionChange = (e) => {
    const value = e.target.value;
    if (showDeductionInput) {
      setDeduction({ val: value, trigger: true });
    } else if (showAdditionInput) {
      setAddition({ val: value, trigger: true });
    }
  };

  const renderMaterialOptions = () => {
    if (!inventoryData || !Array.isArray(inventoryData)) {
      return null;
    }

    return inventoryData.map((item) => (
      <option key={item._id} value={item._id}>
        {item.name}
      </option>
    ));
  };
  // const handleChangeMaterial = (e) => {
  //   const { value } = e.target;

  //   if (value === "custom") {
  //     setShowAddMaterialModal(true);
  //   } else {
  //     setSelectedMaterialId(value);
  //   }
  // };
  // const handleCategoryChange = (e) => {
  //   setCategory(e.target.value);
  // };

  const handleAddMaterialOrder = (index, field, value) => {
    const updatedOrders = [...materialOrders];

    // If the index is null, it means we are adding a new order
    if (index !== null) {
      updatedOrders[index] = {
        ...updatedOrders[index],
        [field]: value,
      };
    } else {
      updatedOrders.push({ category: "", material: "", customCost: "" });
    }

    setMaterialOrders(updatedOrders);
  };

  const handleRemoveMaterialOrder = (index) => {
    setMaterialOrders(materialOrders.filter((_, i) => i !== index));
  };

  return (
    <>
      {loadingCreateOrder ? (
        <Loader />
      ) : (
        <>
          {successOrder ? (
            <Badge
              p={{
                type: "success",
                message: "New Order has been made 🙂",
                redirectName: "Click here to submit another order",
                redirect: "/order/add-order",
                refresh: true,
              }}
            />
          ) : (
            <>
              <div className="row mt-5 mb-4">
                <div className="row">
                  <div className="col-lg-10">
                    <h6 className="mb-2 text-uppercase">Order Info</h6>
                  </div>

                  <div className="col-lg-2">
                    <div className="form-group">
                      {orderDetails.dealerName && orderDetails.productType ? (
                        <button
                          className="btn btn-success w-100"
                          type="submit"
                          onClick={handleSubmitOrder}
                        >
                          Submit the Order
                        </button>
                      ) : (
                        <button
                          className="btn btn-light w-100"
                          type="submit"
                          disabled
                        >
                          Submit the Order
                        </button>
                      )}
                    </div>
                  </div>
                </div>
                <InputSettingsDropdown
                  handleSettingsClick={handleSettingsClick}
                  handleAdditionClick={handleAdditionClick}
                  handleDeductionClick={handleDeductionClick}
                  showSettingsModal={showSettingsModal}
                  handleCloseModal={handleCloseModal}
                />
                {/* Order Headrail,Material,Fabric Selecting Form */}
                {!loadingClient ? (
                  <>
                    <OrderDetailsForm
                      orderDetails={orderDetails}
                      dealer={dealer}
                      handleChange={handleChange}
                      productTypeOptions={productTypeOptions}
                      renderHeadrailOptions={renderHeadrailOptions}
                      showCustomFabricModal={showCustomFabricModal}
                      setShowCustomFabricModal={setShowCustomFabricModal}
                      headrailConfig={headrailConfig}
                      sleeveOption={sleeveOption}
                      handleSleeveChange={handleSleeveChange}
                      Fabrics={Fabrics}
                      LiftStyle={LiftStyle}
                      showCustomLiftStyleModal={showCustomLiftStyleModal}
                      setShowCustomLiftStyleModal={setShowCustomLiftStyleModal}
                    />
                  </>
                ) : (
                  <Loader />
                )}
              </div>
              {orderDetails.productType === "Materials" && (
                <MaterialOrdersTable
                  materialOrders={materialOrders}
                  handleAddMaterialOrder={handleAddMaterialOrder}
                  handleRemoveMaterialOrder={handleRemoveMaterialOrder}
                  showAddMaterialModal={showAddMaterialModal}
                  setShowAddMaterialModal={setShowAddMaterialModal}
                  renderMaterialOptions={renderMaterialOptions}
                />
              )}
              {errToggle.type && <Badge p={errToggle} />}
              <hr />
              {orderDetails.productType !== "Materials" && (
                <>
                  <h6 className="mb-0 text-uppercase">Order Overview </h6>
                  <OrderOverviewUI
                    productType={orderDetails.productType}
                    orderFactor={orderFactor}
                    productMaterial={
                      fabricMapping[orderDetails?.productMaterial] || "N/A"
                    } // Use mapping to get the name
                  />
                </>
              )}

              {/*--------- Adding the product parameters➕ ---------- */}
              {orderDetails.productType && (
                <>
                  <h6 className="mb-0 text-uppercase mb-2">Add Product</h6>
                  {deduction.val && (
                    <NotificationBadge
                      colour="success"
                      icon={<ContentCutIcon />}
                      value={deduction.val}
                      message1="A deduction of"
                      message2="is applying to the width"
                    />
                  )}
                  {addition.val && (
                    <NotificationBadge
                      colour="success"
                      icon={<AddIcon />}
                      value={addition.val}
                      message1="An addition of"
                      message2="is applying to the width"
                    />
                  )}
                  {insertion.val && (
                    <NotificationBadge
                      colour="warning"
                      icon={<SubdirectoryArrowRightIcon />}
                      value={insertion.val}
                      message1="Inserting the values to the index"
                      message2="is active"
                    />
                  )}
                  <div>
                    {/* Render additional input for fraction when needed */}
                    {(showAdditionInput || showDeductionInput) && (
                      <div className="mt-3">
                        <input
                          type="text"
                          value={
                            showDeductionInput ? deduction.val : addition.val
                          }
                          onChange={handleFractionChange}
                          placeholder="Enter fraction (e.g., 1/8)"
                          className="form-control"
                          style={{ width: "150px", display: "inline-block" }}
                        />
                        <button
                          className="btn btn-danger"
                          onClick={handleCancel}
                          style={{ marginLeft: "10px" }}
                        >
                          Cancel
                        </button>
                      </div>
                    )}
                    {orderDetails.productType !== "Materials" && (
                      <div className="container-fluid">
                        <div className="table-card-container">
                          <div
                            className="table-responsive"
                            style={{ overflowX: "auto" }}
                            ref={tableContainerRef}
                          >
                            <table className="table table-bordered table-sm table-border-custom">
                              <InputTableHeader
                                settingsData={settingsData}
                                headrailConfig={calculations[0]}
                              />
                              <TableInputs
                                order={order}
                                handleInputChange={handleInputChange}
                                addRow={addRow}
                                deleteRow={deleteRow}
                                onFocus={handleFocus}
                                inputRefs={inputRefs}
                                isReadOnly={isReadOnly}
                                settingsData={settingsData}
                                headrailConfig={calculations[0]}
                                handleRecalculate={handleRecalculate}
                              />
                            </table>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  {isKeyboardVisible && (
                    <div
                      style={{
                        width: "100%",
                        position: "fixed",
                        bottom: 0,
                        left: 0,
                        padding: 0,
                        margin: 0,
                      }}
                    >
                      <CustomKeyboard
                        onKeyPress={handleKeyPress}
                        onNext={handleNext}
                        onPrevious={handlePrevious}
                        onEnter={handleEnter}
                        onClear={handleClear}
                        onClose={handleCloseKeyboard}
                        handleRecalculate={handleRecalculate}
                        activeRowIndex={activeRowIndex}
                        />
                    </div>
                  )}
                  <hr />
                </>
              )}
            </>
          )}
          <div className="d-md-none" style={{ height: "30vh" }}></div>

          <br />
          <br />
        </>
      )}
    </>
  );
};

export default OrderCreate;
