import React from "react";
import SettingsModal from "../reusables/UseSettingsModal";

const InputSettingsDropdown = ({
  handleSettingsClick,
  handleAdditionClick,
  handleDeductionClick,
  showSettingsModal,
  handleCloseModal,
}) => {
  return (
    <div className="row">
      <div className="col-lg-12">
        <div className="dropdown mt-3">
          <button
            className="btn btn-outline-primary dropdown-toggle float-end"
            type="button"
            id="settingsDropdown"
            data-bs-toggle="dropdown"
            aria-expanded="false"
            style={{
              padding: "8px 16px",
              display: "flex",
              alignItems: "center",
              color: "white",
            }}
          >
            <i className="bi bi-gear"></i>
            Input Settings
          </button>
          <ul className="dropdown-menu" aria-labelledby="settingsDropdown">
            <li>
              <button
                className="dropdown-item"
                onClick={handleSettingsClick}
              >
                <i className="bi bi-gear-wide"></i> Attribute Settings
              </button>
            </li>
            <li>
              <button
                className="dropdown-item"
                onClick={handleAdditionClick}
              >
                <i className="bi bi-plus"></i> Addition
              </button>
            </li>
            <li>
              <button
                className="dropdown-item"
                onClick={handleDeductionClick}
              >
                <i className="bi bi-dash"></i> Deduction
              </button>
            </li>
          </ul>
        </div>

        {showSettingsModal && (
          <SettingsModal
            show={showSettingsModal}
            onClose={handleCloseModal}
          />
        )}
      </div>
    </div>
  );
};

export default InputSettingsDropdown;
