import React from "react";
import { Skeleton, Box, Chip, TextField } from "@mui/material";

const MatrixSkeletonLoader = () => {
  const placeholderRows = 4; // Number of rows for height array
  const placeholderCols = 5; // Number of columns for width array

  return (
    <div className="container mt-4">
      <h2 style={{ color: "white" }}>Edit Matrix</h2>

      {/* Skeleton for Matrix Table */}
      <div className="current-matrix mb-4">
        <h3 style={{ color: "white" }}>Current Matrix</h3>
        <div className="table-responsive">
          <table className="table table-bordered">
            <thead>
              <tr>
                <th rowSpan={2}>
                  <Skeleton
                    variant="rectangular"
                    width={50}
                    height={24}
                    sx={{ bgcolor: "grey.700" }}
                  />
                </th>
                {Array.from({ length: placeholderCols }).map((_, index) => (
                  <th key={index}>
                    <Skeleton
                      variant="rectangular"
                      width={50}
                      height={24}
                      sx={{ bgcolor: "grey.700" }}
                    />
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {Array.from({ length: placeholderRows }).map((_, rowIndex) => (
                <tr key={rowIndex}>
                  <th>
                    <Skeleton
                      variant="rectangular"
                      width={50}
                      height={24}
                      sx={{ bgcolor: "grey.700" }}
                    />
                  </th>
                  {Array.from({ length: placeholderCols }).map((_, colIndex) => (
                    <td key={colIndex}>
                      <Skeleton
                        variant="rectangular"
                        width={50}
                        height={24}
                        sx={{ bgcolor: "grey.700" }}
                      />
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {/* Skeleton for Product Dependencies */}
      <div className="mb-3">
        <label style={{ color: "white" }}>Product Dependencies</label>
        <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1, mt: 2 }}>
          {Array.from({ length: 3 }).map((_, index) => (
            <Skeleton
              key={index}
              variant="rectangular"
              width={70}
              height={32}
              sx={{ bgcolor: "grey.700", borderRadius: 2 }}
            />
          ))}
        </Box>
      </div>

      {/* Skeleton for Width and Height Arrays */}
      <div className="row mb-3">
        <div className="col-md-5 mb-2">
          <label style={{ color: "white" }}>Width Array</label>
          <Skeleton variant="rectangular" height={40} sx={{ bgcolor: "grey.700", borderRadius: 1 }} />
        </div>
        <div className="col-md-5 mb-2">
          <label style={{ color: "white" }}>Height Array</label>
          <Skeleton variant="rectangular" height={40} sx={{ bgcolor: "grey.700", borderRadius: 1 }} />
        </div>
        <div className="col-md-2 d-flex align-items-center justify-content-center">
          <Skeleton variant="rectangular" width={120} height={40} sx={{ bgcolor: "grey.700", borderRadius: 1 }} />
        </div>
      </div>

      {/* Skeleton for Editable Matrix */}
      <div className="mb-3">
        <h4 style={{ color: "white" }}>Matrix</h4>
        <div className="table-responsive">
          <table className="table table-bordered">
            <thead>
              <tr>
                <th rowSpan={2}>
                  <Skeleton
                    variant="rectangular"
                    width={50}
                    height={24}
                    sx={{ bgcolor: "grey.700" }}
                  />
                </th>
                {Array.from({ length: placeholderCols }).map((_, index) => (
                  <th key={index}>
                    <Skeleton
                      variant="rectangular"
                      width={50}
                      height={24}
                      sx={{ bgcolor: "grey.700" }}
                    />
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {Array.from({ length: placeholderRows }).map((_, rowIndex) => (
                <tr key={rowIndex}>
                  <th>
                    <Skeleton
                      variant="rectangular"
                      width={50}
                      height={24}
                      sx={{ bgcolor: "grey.700" }}
                    />
                  </th>
                  {Array.from({ length: placeholderCols }).map((_, colIndex) => (
                    <td key={colIndex}>
                      <Skeleton
                        variant="rectangular"
                        width={50}
                        height={24}
                        sx={{ bgcolor: "grey.700" }}
                      />
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {/* Skeleton for Buttons */}
      <div className="d-flex flex-row mt-3">
        <Skeleton variant="rectangular" width={120} height={40} sx={{ bgcolor: "grey.700", borderRadius: 1, mr: 2 }} />
        <Skeleton variant="rectangular" width={120} height={40} sx={{ bgcolor: "grey.700", borderRadius: 1 }} />
      </div>
    </div>
  );
};

export default MatrixSkeletonLoader;
