// HeadrailConfig.js
import { Checkbox, TextField, FormControlLabel } from "@mui/material";

const HeadrailConfig = ({ formData, handleChange }) => (
  <>
    <div className="col-12">
      <FormControlLabel
        control={
          <Checkbox
            name="sleeveOn"
            checked={formData.sleeveOn}
            onChange={(e) => {
              handleChange(e);
              if (e.target.checked) {
                handleChange({ target: { name: "sleeveOff", value: false } });
              }
            }}
            sx={{ color: "#fff" }}
          />
        }
        label={<span className="text-light">Sleeve On</span>}
      />
      {formData.sleeveOn && (
        <TextField
          type="number"
          label="Add-On Price"
          name="sleeveOnAddonPrice"
          value={formData.sleeveOnAddonPrice}
          onChange={handleChange}
          fullWidth
          style={{ marginTop: "10px", color: "#fff" }}
          InputLabelProps={{
            style: { color: "#fff" },
          }}
          inputProps={{
            style: { color: "#fff" },
          }}
        />
      )}
    </div>

    <div className="col-12">
      <FormControlLabel
        control={
          <Checkbox
            name="sleeveOff"
            checked={formData.sleeveOff}
            onChange={(e) => {
              handleChange(e);
              if (e.target.checked) {
                handleChange({ target: { name: "sleeveOn", value: false } });
              }
            }}
            sx={{ color: "#fff" }}
          />
        }
        label={<span className="text-light">Sleeve Off</span>}
      />
    </div>
  </>
);

export default HeadrailConfig;
