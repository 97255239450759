import { cvrtDeci2Frac, getDecimal, getFinalVal, nearestGreaterVal, totalDecimal } from "../GolbalFunctions";
import { calculateHeadrailFormulas } from "../rollerShades/RollerProduction";
// import { heightArray, widthArray, wMatrix } from "./fauxwoodMatrix";


export function fauxwoodProduction(w, h, headrailConfig, matrix) {
  const { heightArray, widthArray, matrix: matrixArray } = matrix;
  
  
  // Convert all matrix values to numbers
  const numericMatrixArray = matrixArray.map(row => row.map(value => parseFloat(value)));
  console.log(numericMatrixArray);

  const wFraction = totalDecimal(w);
  const hFraction = totalDecimal(h);
  const wNearest = nearestGreaterVal(widthArray, wFraction);
  const hNearest = nearestGreaterVal(heightArray, hFraction);

  const dimensions = { width: wFraction, height: hFraction };

  let calculatedValues = {};
  if (headrailConfig && headrailConfig.formulas) {
      calculatedValues = calculateHeadrailFormulas(
          headrailConfig.formulas,
          dimensions
      );
  }

  const widthIndex = widthArray.indexOf(wNearest);
  const heightIndex = heightArray.indexOf(hNearest);

  // Use the numeric matrix for fullPrice
  const fullPrice = numericMatrixArray[widthIndex][heightIndex];

  return {
      ...calculatedValues,
      fullPrice
  };
}
