import React, { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2'; // Use Line for Area Chart
import axios from 'axios';
import { useSelector } from 'react-redux';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, Filler } from 'chart.js';
import { API_URI } from '../../../constants/GlobalConstants';
import Loader from '../../../components/layout/Loader';

// Register the components for Chart.js
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, Filler);

const DealerOrdersChart = () => {
  const [chartData, setChartData] = useState({});
  const [loading, setLoading] = useState(true);
  
  // Access user info from Redux state
  const userLogin = useSelector((state) => state.userSignIn);
  const { userInfo } = userLogin;

  useEffect(() => {
    const fetchDealerOrders = async () => {
      try {
        const config = {
          headers: {
            Authorization: `Bearer ${userInfo.token}`,
          },
        };

        const { data } = await axios.get(`${API_URI}/api/analytics/dealer-orders-6-months`, config);
        
        const dealers = [...new Set(data.map(order => order._id.dealerName))];
        const months = [...new Set(data.map(order => `${order._id.month}-${order._id.year}`))].sort();

        const datasets = dealers.map(dealer => {
          return {
            label: dealer,
            data: months.map(month => {
              const matchingOrder = data.find(order => 
                `${order._id.month}-${order._id.year}` === month && order._id.dealerName === dealer
              );
              return matchingOrder ? matchingOrder.orderCount : 0;
            }),
            backgroundColor: getRandomColor(0.4), 
            borderColor: getRandomColor(1),
            fill: true, 
            tension: 0.3,
          };
        });

        setChartData({
          labels: months.map(month => formatMonthYear(month)),
          datasets: datasets,
        });

        setLoading(false);
      } catch (error) {
        console.error('Error fetching dealer orders', error);
      }
    };

    fetchDealerOrders();
  }, [userInfo.token]);

  const getRandomColor = (alpha) => {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return alpha ? `${color}${Math.floor(alpha * 255).toString(16).padStart(2, '0')}` : color; // Adjust alpha
  };

  const formatMonthYear = (monthYear) => {
    const [month, year] = monthYear.split('-');
    const date = new Date(year, month - 1); 
    return date.toLocaleString('default', { month: 'long', year: 'numeric' });
  };

  if (loading) return <Loader />;

  return (
    <div>
      <h4 className="mb-4">Dealer Orders Comparison (Last 6 Months)</h4>
      <Line
        data={chartData}
        options={{
          responsive: true,
          plugins: {
            legend: {
              position: 'top',
            },
            title: {
              display: true,
              text: 'Orders per Dealer by Month',
            },
            tooltip: {
              callbacks: {
                label: function(tooltipItem) {
                  return `${tooltipItem.dataset.label}: ${tooltipItem.raw}`;
                }
              }
            },
          },
          scales: {
            x: {
              title: {
                display: true,
                text: 'Month',
                color: '#333',
              },
            },
            y: {
              title: {
                display: true,
                text: 'Order Count',
                color: '#333',
              },
              beginAtZero: true,
            }
          }
        }}
      />
    </div>
  );
};

export default DealerOrdersChart;
