import React, { useState, useEffect } from "react";
import { Box, Alert, CircularProgress, TextField, Chip } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import {
  createInvItemAction,
  getAllInventoryItems,
} from "../../../redux/actions/InventoryAction";
import { Delete } from "@mui/icons-material";
import { getProductTypesAction } from "../../../redux/actions/ProductTypeactions";

const AddCustomMaterialModal = ({
  show,
  handleClose,
  category,
  onMaterialAdded,
}) => {
  const userLogin = useSelector((state) => state.userSignIn);
  const { userInfo } = userLogin;

  const dispatch = useDispatch();

  const createInvItem = useSelector((state) => state.createInvItemReducer);
  const { loading, success, error } = createInvItem;

  const [customMaterialName, setCustomMaterialName] = useState("");
  const [dependencies, setDependencies] = useState([]);
  const productTypesList = useSelector((state) => state.productTypes);
  const { loading: productLoading, productTypes, error: productError } = productTypesList;

  const availableDependencies = productTypes

  useEffect(() => {
    dispatch(getProductTypesAction(userInfo.token));
  }, [dispatch]);

  const handleAddDependency = (dependency) => {
    if (!dependencies.includes(dependency)) {
      setDependencies([...dependencies, dependency]);
    }
  };

  const handleRemoveDependency = (dependency) => {
    setDependencies(dependencies.filter((dep) => dep !== dependency));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const materialData = {
      name: customMaterialName,
      category,
      proDependency: dependencies,
      qtyPerStock: 0,
      remainingInStock: 0,
      stockAlert: 0,
      stockType: "box",
      totalStock: 0,
    };

    await dispatch(createInvItemAction(materialData, userInfo.token));

    // Notify the parent component about the new material
    onMaterialAdded(materialData);

    // Fetch updated inventory items after adding the new material
    dispatch(getAllInventoryItems(userInfo.token));
  };

  useEffect(() => {
    if (success) {
      setCustomMaterialName("");
      setDependencies([]);
      dispatch({ type: "INV_ITEM_CREATE_RESET" });
      handleClose();
    }
  }, [success, handleClose, dispatch]);
  console.log(category);

  if (!show) return null;


  return (
    <>
      {show && <div className="modal-backdrop fade show"></div>}
      <div
        className={`modal fade ${show ? "show" : ""}`}
        tabIndex="-1"
        style={{ display: show ? "block" : "none" }}
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Add Custom Material</h5>
              <button
                type="button"
                className="btn-close"
                onClick={handleClose}
              ></button>
            </div>
            <div className="modal-body">
              {loading && <CircularProgress />}
              {error && <Alert severity="error">{error}</Alert>}
              {success && (
                <Alert severity="success">Material added successfully!</Alert>
              )}

              <form onSubmit={handleSubmit}>
                <div className="mb-3">
                  <label htmlFor="materialName" className="form-label">
                    Material Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="materialName"
                    value={customMaterialName}
                    onChange={(e) => setCustomMaterialName(e.target.value)}
                    required
                  />
                </div>

                <div className="mb-3">
                  <label htmlFor="dependencies" className="form-label">
                    Dependencies
                  </label>
                  <TextField
                    id="dependencies"
                    fullWidth
                    variant="outlined"
                    // placeholder="Select or add dependencies"
                    value=""
                    InputProps={{
                      startAdornment: (
                        <Box
                          sx={{
                            display: "flex",
                            flexWrap: "wrap",
                            gap: 1,
                            maxHeight: "75px",
                            overflowY: "auto",
                          }}
                        >
                          {dependencies.map((dependency, index) => (
                            <Chip
                              key={index}
                              label={dependency}
                              onDelete={() =>
                                handleRemoveDependency(dependency)
                              }
                              deleteIcon={<Delete />}
                              sx={{
                                bgcolor: "primary.light",
                                color: "primary.contrastText",
                              }}
                            />
                          ))}
                        </Box>
                      ),
                      sx: {
                        padding: "10px 14px",
                        flexWrap: "wrap",
                        height: "auto",
                      },
                    }}
                  />
                  <Box
                    sx={{
                      display: "flex",
                      flexWrap: "wrap",
                      gap: 1,
                      marginTop: 2,
                    }}
                  >
                    {availableDependencies?.map((dependency) => (
                      <Chip
                        key={dependency._id}
                        label={dependency.name}
                        onClick={() => handleAddDependency(dependency.name)}
                        clickable
                        sx={{
                          bgcolor: dependencies.includes(dependency.name)
                            ? "secondary.main"
                            : "primary.light",
                          color: dependencies.includes(dependency.name)
                            ? "white"
                            : "primary.contrastText",
                        }}
                      />
                    ))}
                  </Box>
                </div>

                <button type="submit" className="btn btn-primary">
                  Add Material
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddCustomMaterialModal;
