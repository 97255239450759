import axios from 'axios'
import { MATRIX_FAIL, MATRIX_REQUEST, MATRIX_SUCCESS } from '../../constants/MatrixConstants';
import { API_URI } from '../../constants/GlobalConstants';

export const fetchMatrixByProductType = (productType, token) => async (dispatch) => {
    try {
      dispatch({ type: MATRIX_REQUEST });
      
      const { data } = await axios.get(`${API_URI}/api/matrices/matrix/${productType}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      
      dispatch({
        type: MATRIX_SUCCESS,
        payload: data?.data,
      });
    } catch (error) {
      dispatch({
        type: MATRIX_FAIL,
        payload: error.response && error.response.data.message 
          ? error.response.data.message 
          : error.message,
      });
    }
  };