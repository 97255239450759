import {
  FETCH_CALCULATIONS_FAILURE,
  FETCH_CALCULATIONS_REQUEST,
  FETCH_CALCULATIONS_SUCCESS,
  FETCH_FABRICS_FAILURE,
  FETCH_FABRICS_REQUEST,
  FETCH_FABRICS_SUCCESS,
  FETCH_ITEMS_FAILURE,
  FETCH_ITEMS_REQUEST,
  FETCH_ITEMS_SUCCESS,
  FETCH_LIFT_STYLE_FAILURE,
  FETCH_LIFT_STYLE_REQUEST,
  FETCH_LIFT_STYLE_SUCCESS,
} from "../../constants/InventoryConstants";

export const createInvItemReducer = (state = {}, action) => {
  switch (action.type) {
    case "INV_ITEM_CREATE_REQUEST":
      return { loading: true };
    case "INV_ITEM_CREATE_SUCCESS":
      return { loading: false, success: true, data: action.payload };
    case "INV_ITEM_CREATE_FAIL":
      return { loading: false, error: action.payload };
    case "INV_ITEM_CREATE_RESET":
      return {};
    default:
      return state;
  }
};

export const getInvItemsByCatReducer = (state = {}, action) => {
  switch (action.type) {
    case "INV_ITEMS_GET_REQUEST":
      return { loading: true };
    case "INV_ITEMS_GET_SUCCESS":
      return { loading: false, success: true, data: action.payload };
    case "INV_ITEMS_GET_FAIL":
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const getFabricItemsReducer = (state = {}, action) => {
  switch (action.type) {
    case FETCH_FABRICS_REQUEST:
      return { loading: true };
    case FETCH_FABRICS_SUCCESS:
      return { loading: false, success: true, fabrics: action.payload };
    case FETCH_FABRICS_FAILURE:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

const inventorytablestate = {
  data: [],
  loading: false,
  error: null,
};

export const getInventoryTableData = (state = inventorytablestate, action) => {
  switch (action.type) {
    case FETCH_ITEMS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_ITEMS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    case FETCH_ITEMS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

const calculationsstate = {
  loading: false,
  calculations: [],
  error: null,
};

const calculationReducer = (state = calculationsstate, action) => {
  switch (action.type) {
    case FETCH_CALCULATIONS_REQUEST:
      return { ...state, loading: true, error: null };
    case FETCH_CALCULATIONS_SUCCESS:
      return { ...state, loading: false, calculations: action.payload };
    case FETCH_CALCULATIONS_FAILURE:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export default calculationReducer;


const ListStyleState = {
  loading: false,
  liftStyleItems: [],
  error: null,
};

export const liftStyleReducer = (state = ListStyleState, action) => {
  switch (action.type) {
    case FETCH_LIFT_STYLE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    
    case FETCH_LIFT_STYLE_SUCCESS:
      return {
        ...state,
        loading: false,
        liftStyleItems: action.payload,
      };
    
    case FETCH_LIFT_STYLE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};