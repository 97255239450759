import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Loader from "../../components/layout/Loader";
import {
  ViewDealerAction,
  updateHeadrailConfigAndRates,
} from "../../redux/actions/DealerAction";
import { getInvItemsByCat } from "../../redux/actions/InventoryAction";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { styled } from "@mui/material";

const Alert = styled(MuiAlert)(({ theme }) => ({
  width: "600px",
  height: "60px",
  fontSize: "1rem",
  padding: theme.spacing(2),
  margin: "0 auto",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  marginTop: theme.spacing(5),
}));
const EditRatesOfProducts = () => {
  const dispatch = useDispatch();
  const { id } = useParams();

  const { userInfo } = useSelector((state) => state.userSignIn);
  const {
    loading,
    dealer,
    success: detailSuccess,
  } = useSelector((state) => state.viewDealer);
  const { data: inventoryData } = useSelector(
    (state) => state.getInvItemsByCat
  );
  const items = inventoryData?.[0]?.items || [];

  const [productRates, setProductRates] = useState({});
  const [headrailConfigList, setHeadrailConfigList] = useState([]);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  useEffect(() => {
    if (id && userInfo.token) {
      dispatch(ViewDealerAction(id, userInfo.token));
    }
  }, [dispatch, id, userInfo.token]);

  useEffect(() => {
    if (detailSuccess && dealer) {
      setProductRates(dealer.productRate || {});

      const headrailList = items.map((item) => {
        const dealerHeadrail = dealer.headrail.find(
          (h) => h.itemId.toString() === item.id
        ) || {
          itemId: item.id,
          products: {},
        };

        return {
          name: item.name,
          id: item.id,
          products: dealerHeadrail.products,
        };
      });
      setHeadrailConfigList(headrailList);
    }
  }, [detailSuccess, dealer, items]);

  const handleRateChange = (product, value) => {
    setProductRates((prevRates) => ({ ...prevRates, [product]: value }));
  };

  const handleHeadrailConfigChange = useCallback(
    (index, productName, field, value) => {
      setHeadrailConfigList((prevConfig) => {
        const updatedConfig = [...prevConfig];

        // Split the field to support nested structure
        const keys = field.split(".");
        const product = updatedConfig[index].products[productName];

        if (keys[0] === "sleeveOn" || keys[0] === "sleeveOff") {
          product[keys[0]] = product[keys[0]] || {};
          product[keys[0]][keys[1]] = value;
        }

        return updatedConfig;
      });
    },
    [setHeadrailConfigList]
  );

  const handleSubmit = (e) => {
    e.preventDefault();

    const updatedData = {
      dealerId: id,
      productRate: productRates,
      headrails: headrailConfigList.map((headrail) => ({
        itemId: headrail.id,
        products: headrail.products,
      })),
    };

    // Check if any addon prices are initially 0 or undefined
    const hasZeroAddonPrices = headrailConfigList.some((headrail) =>
      Object.values(headrail.products).some(
        (product) =>
          (product.sleeveOn?.addonPrice === undefined ||
            product.sleeveOn.addonPrice === 0) &&
          (product.sleeveOff?.addonPrice === undefined ||
            product.sleeveOff.addonPrice === 0)
      )
    );

    // Call update backend twice if any addon prices are zero
    if (hasZeroAddonPrices) {
      // First call with current data
      dispatch(updateHeadrailConfigAndRates(updatedData, userInfo.token)).then(
        () => {
          setSnackbarMessage("Initial Update successful!");
          setOpenSnackbar(true);

          // Optionally, here you can set new values for addonPrice if needed
          const updatedPrices = {
            ...updatedData,
            headrails: updatedData.headrails.map((headrail) => ({
              ...headrail,
              products: {
                ...headrail.products,
                sleeveOn: {
                  ...(headrail.products.sleeveOn || {}),
                  addonPrice: 1,
                },
                sleeveOff: {
                  ...(headrail.products.sleeveOff || {}),
                  addonPrice: 1,
                },
              },
            })),
          };

          // Second call with updated values
          dispatch(
            updateHeadrailConfigAndRates(updatedPrices, userInfo.token)
          ).then(() => {
            setSnackbarMessage("Final Update successful!");
            setOpenSnackbar(true);
          });
        }
      );
    } else {
      // Call the update function once if no prices are zero
      dispatch(updateHeadrailConfigAndRates(updatedData, userInfo.token)).then(
        () => {
          setSnackbarMessage("Update successful!");
          setOpenSnackbar(true);
        }
      );
    }
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  useEffect(() => {
    if (userInfo?.token) {
      dispatch(getInvItemsByCat("Headrail", userInfo.token));
    }
  }, [dispatch, userInfo.token]);
  console.log(headrailConfigList);

  return (
    <div className="container mt-4">
      <div className="d-flex justify-content-between align-items-center">
        <h2>Edit Product Rates & Headrail</h2>
        <button type="submit" form="editRatesForm" className="btn btn-primary">
          Update
        </button>
      </div>
      <hr />

      {loading ? (
        <Loader />
      ) : (
        <>
          {detailSuccess ? (
            <form id="editRatesForm" onSubmit={handleSubmit}>
              {/* Product Rates Table */}
              <div className="table-responsive">
                <table className="table table-striped table-bordered table-hover">
                  <thead>
                    <tr>
                      <th>Product</th>
                      <th>Rate (per Unit)</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Object.entries(productRates).map(([product]) => (
                      <tr key={product}>
                        <td>{product}</td>
                        <td>
                          <input
                            type="number"
                            className="form-control"
                            value={productRates[product] || 0}
                            onChange={(e) =>
                              handleRateChange(product, e.target.value)
                            }
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>

              {/* Headrail Configuration Table */}
              <div className="table-responsive mt-4">
                <table className="table table-bordered table-hover">
                  <thead>
                    <tr>
                      <th>Headrail</th>
                      <th>Product Type</th>
                      <th>Sleeve Option</th>
                      <th>Add On</th>
                      <th>Unit Rate</th>
                    </tr>
                  </thead>
                  <tbody>
                    {headrailConfigList.map((headrail, index) => {
                      const products = Object.entries(headrail.products);
                      return products.length > 0 ? (
                        products.map(
                          ([productName, productConfig], productIndex) => (
                            <React.Fragment
                              key={`${headrail.id}-${productName}`}
                            >
                              <tr>
                                {productIndex === 0 && (
                                  <td rowSpan={products.length * 2}>
                                    {headrail.name}
                                  </td>
                                )}
                                {!productConfig.sleeveOff.isTrue ? (
                                  <td>
                                    {productConfig._id
                                      ? productName
                                      : "Unknown Product"}
                                  </td>
                                ) : null}{" "}
                                {/* Only render Sleeve On if Sleeve Off doesn't exist */}
                                {!productConfig.sleeveOff.isTrue ? (
                                  <>
                                    <td>Sleeve On</td>
                                    <td>
                                      <input
                                        type="number"
                                        className="form-control"
                                        value={
                                          productConfig.sleeveOn?.addonPrice ??
                                          ""
                                        }
                                        onChange={(e) => {
                                          const newValue =
                                            e.target.value === ""
                                              ? ""
                                              : parseFloat(e.target.value);
                                          handleHeadrailConfigChange(
                                            index,
                                            productName,
                                            "sleeveOn.addonPrice",
                                            isNaN(newValue) ? 0 : newValue
                                          );
                                        }}
                                        required={true}
                                      />
                                    </td>
                                    <td>
                                      <select
                                        className="form-control"
                                        value={
                                          productConfig.sleeveOn?.unitRate ||
                                          "sqft"
                                        } // Default value for unit rate
                                        onChange={(e) =>
                                          handleHeadrailConfigChange(
                                            index,
                                            productName,
                                            "sleeveOn.unitRate",
                                            e.target.value
                                          )
                                        }
                                      >
                                        <option value="sqft">SQFT</option>

                                        {/* Add more unit options as necessary */}
                                      </select>
                                    </td>
                                  </>
                                ) : null}{" "}
                                {/* Render nothing if sleeveOff exists */}
                              </tr>

                              {/* Render Sleeve Off row if it exists */}
                              {productConfig.sleeveOff && (
                                <tr>
                                  <td>
                                    {productConfig._id
                                      ? productName
                                      : "Unknown Product"}
                                  </td>
                                  <td>Sleeve Off</td>
                                  <td>
                                    <input
                                      type="number"
                                      className="form-control"
                                      value={
                                        productConfig.sleeveOff?.addonPrice ??
                                        ""
                                      }
                                      required={true}
                                      onChange={(e) => {
                                        const newValue =
                                          e.target.value === ""
                                            ? ""
                                            : parseFloat(e.target.value);
                                        handleHeadrailConfigChange(
                                          index,
                                          productName,
                                          "sleeveOff.addonPrice",
                                          isNaN(newValue) ? 0 : newValue
                                        );
                                      }}
                                    />
                                  </td>
                                  <td>
                                    <select
                                      className="form-control"
                                      value={
                                        productConfig.sleeveOff?.unitRate ||
                                        "sqft"
                                      } // Default value for unit rate
                                      onChange={(e) =>
                                        handleHeadrailConfigChange(
                                          index,
                                          productName,
                                          "sleeveOff.unitRate",
                                          e.target.value
                                        )
                                      }
                                    >
                                      <option value="sqft">SQFT</option>

                                      {/* Add more unit options as necessary */}
                                    </select>
                                  </td>
                                </tr>
                              )}
                            </React.Fragment>
                          )
                        )
                      ) : (
                        <tr key={headrail.id}>
                          <td colSpan={4}>
                            No products available for this headrail.
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>

              <button type="submit" className="btn btn-primary mt-4">
                Update
              </button>
            </form>
          ) : (
            <p>No dealer details available.</p>
          )}

          {/* Snackbar for success message */}
          <Snackbar
            open={openSnackbar}
            autoHideDuration={3000}
            onClose={handleSnackbarClose}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
          >
            <Alert onClose={handleSnackbarClose} severity="success">
              {snackbarMessage}
            </Alert>
          </Snackbar>
        </>
      )}
    </div>
  );
};

export default EditRatesOfProducts;
