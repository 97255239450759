import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Badge,
  Typography,
  TableSortLabel,
  Checkbox,
  Box,
  Button,
  MenuItem,
  Menu,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import axios from "axios";
import { useSelector } from "react-redux";
import { API_URI } from "../../constants/GlobalConstants";
import fileDownload from "js-file-download";

const OrderList = ({
  p = { orders: [] },
  order = "asc",
  orderBy = "dealerName",
  onRequestSort = () => { },
  setRefresh = () => { },
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [exportMenuAnchorEl, setExportMenuAnchorEl] = useState(null);
  const [selectedOrders, setSelectedOrders] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const menuRef = useRef(null);

  const userLogin = useSelector((state) => state.userSignIn);
  const { userInfo } = userLogin;

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleExportMenuOpen = (event) => {
    setExportMenuAnchorEl(event.currentTarget);
  };

  const handleExportMenuClose = () => {
    setExportMenuAnchorEl(null);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      setSelectedOrders(p.orders.map((order) => order._id));
      setSelectAll(true);
    } else {
      setSelectedOrders([]);
      setSelectAll(false);
    }
  };

  const handleClick = (id) => {
    setSelectedOrders((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((item) => item !== id)
        : [...prevSelected, id]
    );
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        handleMenuClose();
        handleExportMenuClose();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const headCells = [
    { id: "select", numeric: false, disablePadding: true, label: "Select" },
    {
      id: "dealerName",
      numeric: false,
      disablePadding: false,
      label: "Dealer",
    },
    {
      id: "productType",
      numeric: false,
      disablePadding: false,
      label: "Product",
    },
    {
      id: "productTag",
      numeric: false,
      disablePadding: false,
      label: "ProductTag",
    },
    {
      id: "Date",
      numeric: false,
      disablePadding: false,
      label: "date",
    },
    {
      id: "productMaterial",
      numeric: false,
      disablePadding: false,
      label: "Material",
    },
    {
      id: "orderArrayLength",
      numeric: true,
      disablePadding: false,
      label: "# products",
    },
    {
      id: "orderStatus",
      numeric: false,
      disablePadding: false,
      label: "Status",
    },
  ];

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  const sortedOrders = [...(p.orders || [])].sort((a, b) => {
    if (a[orderBy] < b[orderBy]) {
      return order === "asc" ? -1 : 1;
    }
    if (a[orderBy] > b[orderBy]) {
      return order === "asc" ? 1 : -1;
    }
    return 0;
  });

  const updateOrderStatus = async (status) => {
    try {
      console.log("Updating status to:", status);
      const response = await axios.put(
        `${API_URI}/api/order/update-status`,
        { ids: selectedOrders, status },
        {
          headers: {
            Authorization: `Bearer ${userInfo.token}`,
          },
        }
      );
      console.log("Status update response:", response);
      setRefresh(true);
      setSelectedOrders([]);
      setSelectAll(false);
      handleMenuClose();
    } catch (error) {
      console.error("Error updating order status:", error);
    }
  };

  const exportOrderSheet = async () => {
    try {
      const orderId = selectedOrders[0];
      const { data } = await axios.get(
        `${API_URI}/api/order/export-excel-order-label/${orderId}`,
        {
          headers: {
            Authorization: `Bearer ${userInfo.token}`,
          },
          responseType: "blob",
        }
      );
      fileDownload(data, `OrderLabel.xlsx`);
    } catch (error) {
      alert("😭 Oop! Internal Error, contact the Admin🤓 Error:", error);
    }
  };

  const exportOrderPdf = async () => {
    try {
      const orderId = selectedOrders[0];
      const { data } = await axios.get(
        `${API_URI}/api/order/print-production-sheet/${orderId}`,
        {
          headers: {
            Authorization: `Bearer ${userInfo?.token}`,
          },
          responseType: "blob",
        }
      );
      fileDownload(data, `OrderSheet.pdf`);
    } catch (error) {
      console.error("Error exporting order PDF:", error);
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
};
console.log(sortedOrders);
  return (
    <Box>
      {selectedOrders.length > 0 && (
        <Box mb={2} display="flex" justifyContent="flex-end" gap={1}>
          {(userInfo?.roles?.includes("admin") ||
            userInfo?.roles?.includes("production_manager") ||
            userInfo?.isAdmin) && (
              <Button
                variant="contained"
                color="primary"
                onClick={handleMenuOpen}
                endIcon={<MoreVertIcon />}
              >
                Change Status
              </Button>
            )}
          <Button
            variant="contained"
            color="secondary"
            onClick={handleExportMenuOpen}
            endIcon={<MoreVertIcon />}
          >
            Export
          </Button>
          <Menu
            id="export-menu"
            anchorEl={exportMenuAnchorEl}
            keepMounted
            open={Boolean(exportMenuAnchorEl)}
            onClose={handleExportMenuClose}
          >
            <MenuItem onClick={exportOrderPdf}>Print Production Sheet</MenuItem>
            <MenuItem onClick={exportOrderSheet}>Export Order Label</MenuItem>
          </Menu>
          <Menu
            id="status-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
            ref={menuRef}
          >
            <MenuItem onClick={() => updateOrderStatus("ready_to_process")}>
              Ready to Process
            </MenuItem>
            <MenuItem onClick={() => updateOrderStatus("processing")}>
              Processing
            </MenuItem>
            <MenuItem onClick={() => updateOrderStatus("processed")}>
              Processed
            </MenuItem>
            <MenuItem onClick={() => updateOrderStatus("delivered")}>
              Delivered
            </MenuItem>
          </Menu>
        </Box>
      )}

      <TableContainer component={Paper} className="mt-3">
        <Table>
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox">
                <Checkbox
                  color="primary"
                  indeterminate={
                    selectedOrders.length > 0 &&
                    selectedOrders.length < (p.orders || []).length
                  }
                  checked={selectAll}
                  onChange={handleSelectAllClick}
                />
              </TableCell>
              {headCells.map((headCell) => (
                <TableCell
                  key={headCell.id}
                  align={headCell.numeric ? "right" : "left"}
                  padding={headCell.disablePadding ? "none" : "normal"}
                  sortDirection={orderBy === headCell.id ? order : false}
                >
                  <TableSortLabel
                    active={orderBy === headCell.id}
                    direction={orderBy === headCell.id ? order : "asc"}
                    onClick={createSortHandler(headCell.id)}
                  >
                    {headCell.label}
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedOrders.map((order) => (
              <TableRow
                key={order._id}
                hover
                role="checkbox"
                aria-checked={selectedOrders.includes(order._id)}
                tabIndex={-1}
                selected={selectedOrders.includes(order._id)}
              >
                <TableCell padding="checkbox">
                  <Checkbox
                    color="primary"
                    checked={selectedOrders.includes(order._id)}
                    onChange={() => handleClick(order._id)}
                    inputProps={{ "aria-labelledby": `order-${order._id}` }}
                  />
                </TableCell>
                <TableCell padding="checkbox"> </TableCell>
                <TableCell>
                  <Link to={`/order/order-detail/${order._id}`}>
                    <Typography color="textPrimary" variant="body2">
                      {order.dealerName}
                    </Typography>
                  </Link>
                </TableCell>
                <TableCell>
                  <Link to={`/order/order-detail/${order._id}`}>
                    <Typography color="textPrimary" variant="body2">
                      {order.productType}
                    </Typography>
                  </Link>
                </TableCell>
                <TableCell>
                  <Link to={`/order/order-detail/${order._id}`}>
                    <Typography color="textPrimary" variant="body2">
                      {order.tag}
                    </Typography>
                  </Link>
                </TableCell>
                <TableCell>
                  <Link to={`/order/order-detail/${order._id}`}>
                    <Typography color="textPrimary" variant="body2">
                      {formatDate(order.createdAt)}
                    </Typography>
                  </Link>
                </TableCell>
                <TableCell>
                  <Link to={`/order/order-detail/${order._id}`}>
                    <Typography color="textPrimary" variant="body2">
                      {order?.productMaterial?.name}
                    </Typography>
                  </Link>
                </TableCell>
                <TableCell align="right">
                  <Link to={`/order/order-detail/${order._id}`}>
                    <Typography color="textPrimary" variant="body2">
                      {order.orderArray.length}
                    </Typography>
                  </Link>
                </TableCell>
                <TableCell>
                  <Badge
                    color={
                      order.orderStatus === "delivered"
                        ? "success"
                        : order.orderStatus === "processed"
                          ? "info"
                          : order.orderStatus === "processing"
                            ? "warning"
                            : "default"
                    }
                    variant="dot"
                  >
                    {order.orderStatus === "ready_to_process" &&
                      "Ready to Process"}
                    {order.orderStatus === "processing" && "Processing"}
                    {order.orderStatus === "processed" && "Processed"}
                    {order.orderStatus === "delivered" && "Delivered"}
                  </Badge>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default OrderList;
