import React from 'react';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  LineElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend
} from 'chart.js';

ChartJS.register(
  LineElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend
);

const ProductionBarByMonth = ({ monthlyOrderData }) => {
  // Check if monthlyOrderData is available
  const labels = monthlyOrderData ? Object.keys(monthlyOrderData) : [];
  const orderCounts = labels.map(label => monthlyOrderData[label]?.orderCount || 0);
  const productCounts = labels.map(label => monthlyOrderData[label]?.productCount || 0);

  const data = {
    labels,
    datasets: [
      {
        label: 'Order Count',
        data: orderCounts,
        backgroundColor: 'rgba(0, 255, 255, 0.5)', // Aqua color with transparency
        borderColor: 'aqua',
        borderWidth: 1,
        fill: true, // Enable area filling
      },
      {
        label: 'Product Count',
        data: productCounts,
        backgroundColor: 'rgba(0, 128, 0, 0.5)', // Green color with transparency
        borderColor: 'green',
        borderWidth: 1,
        fill: true, // Enable area filling
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        title: {
          display: true,
          text: 'Months',
        },
      },
      y: {
        title: {
          display: true,
          text: 'Count',
        },
      },
    },
  };

  return (
    <div style={{ height: '400px' }}>
      <Line data={data} options={options} />
    </div>
  );
};

export default ProductionBarByMonth;
