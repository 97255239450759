import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { Link } from "react-router-dom";
import { NoteModel } from "../../components/dealer/profile/notes/NoteModel";
import ViewDealerTable from "../../components/dealer/ViewDealerTable";
import Header from "../../components/layout/Header";
import Loader from "../../components/layout/Loader";
import LoaderLin from "../../components/layout/LoaderLin";
import Sidebar from "../../components/layout/Sidebar";
import { objToArr } from "../../functions/general/Function";
import {
  DealerOrderStatsAction,
  UpdateDealerNotesAction,
  ViewDealerAction,
} from "../../redux/actions/DealerAction";

const ViewDealer = ({ nav }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();

  const userLogin = useSelector((state) => state.userSignIn);
  const { error, userInfo } = userLogin;
  const dealerDetail = useSelector((state) => state.viewDealer);
  const { loading, dealer, success: detailSuccess } = dealerDetail;
  const orderStats = useSelector((state) => state.dealerOrderStats);
  const { loading: statsLoading, stats, success: statsSuccess } = orderStats;
  const updateNotesSubmit = useSelector((state) => state.updateDealerNotes);
  const { loading: noteLoading, success: nodesSuccess } = updateNotesSubmit;

  //Modal
  const [open, setOpen] = useState(false);
  const [updateNotes, setUpdateNotes] = useState("");

  //Modal
  const handleOpen = () => {
    setOpen(true);
    setUpdateNotes(dealer.notes);
  };
  const handleUpdateNotes = async () => {
    setOpen(false);
    const val = {
      dealerId: id,
      notes: updateNotes,
    };
    await dispatch(UpdateDealerNotesAction(val, userInfo.token));
    await dispatch(ViewDealerAction(id, userInfo.token));
  };

  useEffect(() => {
    dispatch({ type: "DEALER_DETAIL_RESET" });
    if (!userInfo) {
      navigate("/sign-in");
    }
    window.scrollTo(0, 0);
    if (window.innerWidth > 1200) {
      nav.setIsSidebar("wrapper toggled");
    }
  }, [userInfo, navigate]);

  useEffect(() => {
    dispatch({ type: "DEALER_DETAIL_RESET" });
    setUpdateNotes("");
    dispatch(ViewDealerAction(id, userInfo.token));
    dispatch(DealerOrderStatsAction(id, userInfo.token));
  }, [id]);

  return (
    <>
      <div className={nav.isSidebar}>
        <Header nav={nav} />
        <Sidebar nav={nav} />
        <main className="page-content">
          <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
            <div className="breadcrumb-title pe-3">Dashboard</div>
            <div className="ps-3">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb mb-0 p-0">
                  <li className="breadcrumb-item">
                    <Link to="/">
                      <i className="bx bx-home-alt" />
                    </Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/dealer/list-dealer">List All Dealers</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    View Dealer
                  </li>
                </ol>
              </nav>
            </div>
            {/* Setting dropdown button */}
            <div className="ms-auto">
              <div className="btn-group">
                <button
                  type="button"
                  className="btn btn-light "
                  data-bs-toggle="dropdown"
                >
                  Edit Dealer Details
                </button>
                <button
                  type="button"
                  className="btn btn-light split-bg-light dropdown-toggle dropdown-toggle-split"
                  data-bs-toggle="dropdown"
                >
                  {" "}
                  <span className="visually-hidden">Toggle Dropdown</span>
                </button>
                <div className="dropdown-menu dropdown-menu-right dropdown-menu-lg-end">
                  {" "}
                  <Link
                    className="dropdown-item text-light"
                    to={`/dealer/edit-profile/${id}`}
                  >
                    Edit Dealer
                  </Link>
                  <Link
                    className="dropdown-item text-light"
                    to={`/dealer/edit-product-rate/${id}`}
                  >
                    Edit Product Rate
                  </Link>
                </div>
              </div>
            </div>
          </div>
          {/*----Note MODEL--*/}
          {detailSuccess ? (
            NoteModel(
              open,
              setOpen,
              updateNotes,
              setUpdateNotes,
              handleUpdateNotes
            )
          ) : (
            <Loader />
          )}
          {/*----Overview Section--*/}
          <ViewDealerTable d={{ dealer, detailSuccess, stats, statsSuccess }} />
          <h6 className="mb-0 text-uppercase">Dealer Action Section</h6>
          <hr />
          <div className="row row-cols-1 row-cols-sm-2 row-cols-md-2 row-cols-xl-3 row-cols-xxl-3">
            <div className="col">
              <div className="card-body">
                <div className="d-flex justify-content-center mt-3">
                  <Link to={`/dealer/transactions/${id}`}>
                    <button
                      type="button"
                      className="btn btn-lg btn-info px-5 radius-30 btn-block"
                    >
                      <i className="fadeIn animated bx bx-dollar-circle" />
                      Transaction History
                    </button>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="card-body">
                <div className="d-flex justify-content-center mt-3">
                  <Link to={`/dealer/account-view/${id}`}>
                    <button
                      type="button"
                      className="btn btn-lg btn-info px-5 radius-30"
                    >
                      <i className="fadeIn animated bx bx-coin-stack" />
                      Accounts Management
                    </button>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="card-body">
                <div className="d-flex justify-content-center mt-3 mb-4">
                  {loading ? (
                    <LoaderLin />
                  ) : (
                    <button
                      type="button"
                      className="btn btn-lg btn-info px-5 radius-30"
                      onClick={handleOpen}
                    >
                      <i className="fadeIn animated bx bx-notepad" />
                      Dealer Notes / Remarks
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
          <h6 className="mb-0 text-uppercase">Dealer Overview</h6>
          <hr />
          <div className="card radius-10">
            <div className="card-body">
              <div className="row g-3">
                <div className="col-12 col-lg-4 col-xl-4 d-flex">
                  <div className="card mb-0 radius-10 border shadow-none w-100">
                    <div className="card-body">
                      <div className="d-flex justify-content-between align-items-center">
                        <h5 className="card-title">
                          <i className="fadeIn animated bx bx-dollar" />
                          Products rates
                        </h5>
                        <Link
                          className="btn btn-sm btn-primary"
                          to={`/dealer/edit-product-rate/${id}`}
                        >
                          Edit Product Rate
                        </Link>
                      </div>
                      {loading ? (
                        <Loader />
                      ) : (
                        <>
                          <p className="mb-0 text-secondary font-13">
                            These are the current product rates. To edit/update
                            the rates go to Dealer Product rate management
                          </p>
                          <ul className="list-group list-group-flush mt-3">
                            {/* {dealer && getTheRates(dealer.productRate)} */}
                            {
                              // dealer && console.log(objToArr(dealer.productRate))
                              dealer &&
                                objToArr(dealer.productRate).map((arr) => (
                                  <li className="list-group-item border-top">
                                    <div className="d-flex align-items-center gap-2">
                                      <div>
                                        <i className="fadeIn animated bx bx-caret-right" />
                                      </div>
                                      <div>{arr[0]}</div>
                                      <div className="ms-auto">
                                        $ <b>{arr[1]}</b> / SQFT
                                      </div>
                                    </div>
                                  </li>
                                ))
                            }
                          </ul>
                        </>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-12 col-lg-8 col-xl-8 d-flex">
                  <div className="card mb-0 radius-10 border shadow-none w-100">
                    <div className="card-body">
                      <h5 className="card-title">
                        <i class="fadeIn animated bx bx-notepad" />{" "}
                        Notes/Remarks{" "}
                      </h5>
                      {loading ? (
                        <Loader />
                      ) : (
                        <>
                          <p className="mb-0 text-secondary">
                            {dealer ? dealer.notes : "No Notes😎"}
                          </p>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {/*end row*/}
            </div>
          </div>
        </main>

        <div className="overlay nav-toggle-icon" />
        <a href="" className="back-to-top">
          <i className="bx bxs-up-arrow-alt" />
        </a>
      </div>
    </>
  );
};

export default ViewDealer;
