import axios from "axios";
import { CREATE_PRODUCT_TYPE_FAIL, CREATE_PRODUCT_TYPE_REQUEST, CREATE_PRODUCT_TYPE_SUCCESS, PRODUCT_TYPES_FAIL, PRODUCT_TYPES_REQUEST, PRODUCT_TYPES_SUCCESS } from "../../constants/ProductTypeConstants";
import { API_URI } from "../../constants/GlobalConstants";

export const getProductTypesAction = (token) => async (dispatch) => {
    try {
      dispatch({ type: PRODUCT_TYPES_REQUEST });
  
  
      const config = {
        headers: {
          Authorization: `Bearer ${token}`, 
        },
      };
  
      const { data } = await axios.get(`${API_URI}/api/product-types`, config);
      dispatch({ type: PRODUCT_TYPES_SUCCESS, payload: data });
    } catch (error) {
      dispatch({
        type: PRODUCT_TYPES_FAIL,
        payload: error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
      });
    }
  };

  export const createProductTypeAction = (productType,token) => async (dispatch) => {
    try {
      dispatch({ type: CREATE_PRODUCT_TYPE_REQUEST });
    
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`, 
        },
      };
  
      const { data } = await axios.post(`${API_URI}/api/product-types/create`, productType, config);
      dispatch({ type: CREATE_PRODUCT_TYPE_SUCCESS, payload: data });
    } catch (error) {
      dispatch({
        type: CREATE_PRODUCT_TYPE_FAIL,
        payload: error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
      });
    }
  };