import React, { useEffect, useState } from "react";
import { TextField, Chip, Box, Snackbar, Alert } from "@mui/material";
import { Delete } from "@mui/icons-material";
import { API_URI } from "../../constants/GlobalConstants";
import { useDispatch, useSelector } from "react-redux";
import { getProductTypesAction } from "../../redux/actions/ProductTypeactions";
import axios from "axios";

const DynamicMatrixTable = () => {
  const [widthArray, setWidthArray] = useState([]);
  const [heightArray, setHeightArray] = useState([]);
  const [matrix, setMatrix] = useState([]); // Matrix in column-wise format
  const [widthInput, setWidthInput] = useState("");
  const [heightInput, setHeightInput] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [dependencies, setDependencies] = useState([]);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");

  const productTypesList = useSelector((state) => state.productTypes);
  const {
    loading: productLoading,
    productTypes,
    error: productError,
  } = productTypesList;
  const userLogin = useSelector((state) => state.userSignIn);
  const { userInfo } = userLogin;

  const dispatch = useDispatch();
  const availableDependencies = productTypes;

  useEffect(() => {
    dispatch(getProductTypesAction(userInfo.token));
  }, [dispatch]);

  const validateInput = (input) => {
    return input.split(",").every((val) => !isNaN(val) && val.trim() !== "");
  };

  const handleAddDependency = (dependency) => {
    if (!dependencies.includes(dependency)) {
      setDependencies([...dependencies, dependency]);
    }
  };

  const handleRemoveDependency = (dependency) => {
    setDependencies(dependencies.filter((dep) => dep !== dependency));
  };

  const handleApplyArrays = () => {
    if (!validateInput(widthInput) || !validateInput(heightInput)) {
      setErrorMessage(
        "Both width and height inputs must be comma-separated lists of numbers."
      );
      return;
    }

    const widthValues = widthInput.split(",").map(Number);
    const heightValues = heightInput.split(",").map(Number);

    setWidthArray(widthValues);
    setHeightArray(heightValues);

    // Initialize matrix with column-wise arrays
    const initialMatrix = widthValues.map(() =>
      Array(heightValues.length).fill("")
    );
    setMatrix(initialMatrix);

    setErrorMessage("");
  };

  const handleMatrixChange = (rowIndex, colIndex, value) => {
    const updatedMatrix = [...matrix];
    updatedMatrix[colIndex][rowIndex] = value;
    setMatrix(updatedMatrix);
  };

  const saveMatrix = async (matrixData) => {
    try {
      const response = await axios.post(`${API_URI}/api/matrices`, matrixData, {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      });
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message || "Failed to save matrix");
    }
  };

  const handleSubmit = async () => {
    const matrixData = {
      productDependencies: dependencies,
      widthArray: widthArray,
      heightArray: heightArray,
      matrix: matrix,
    };

    try {
      const result = await saveMatrix(matrixData);
      console.log("Matrix saved successfully:", result);
      setSnackbarMessage("Matrix saved successfully!");
      setSnackbarSeverity("success");
      setOpenSnackbar(true);
    } catch (error) {
      console.error("Error saving matrix:", error.message);
      setSnackbarMessage(error.message);
      setSnackbarSeverity("error");
      setOpenSnackbar(true);
    }
  };

  const handleClear = () => {
    setWidthArray([]);
    setHeightArray([]);
    setMatrix([]);
    setWidthInput("");
    setHeightInput("");
    setDependencies([]);
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };
  console.log(matrix);
  return (
    <div className="container mt-4">
      <h2>Dynamic Matrix Input</h2>

      {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}

      <div className="mb-3">
        <label htmlFor="dependencies" className="form-label">
          Product Dependency
        </label>
        <TextField
          id="dependencies"
          fullWidth
          variant="outlined"
          value=""
          InputProps={{
            startAdornment: (
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  gap: 1,
                  maxHeight: "75px",
                  overflowY: "auto",
                }}
              >
                {dependencies.map((dependency, index) => (
                  <Chip
                    key={index}
                    label={dependency}
                    onDelete={() => handleRemoveDependency(dependency)}
                    deleteIcon={<Delete />}
                    sx={{
                      bgcolor: "primary.light",
                      color: "primary.contrastText",
                      fontSize: "0.75rem",
                      padding: "2px 4px",
                      height: "24px",
                    }}
                  />
                ))}
              </Box>
            ),
            sx: { padding: "10px 14px", flexWrap: "wrap", height: "auto" },
          }}
        />
        <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1, marginTop: 2 }}>
          {availableDependencies.map((dependency) => (
            <Chip
              key={dependency._id}
              label={dependency.name}
              onClick={() => handleAddDependency(dependency.name)}
              clickable
              sx={{
                bgcolor: dependencies.includes(dependency.name)
                  ? "secondary.main"
                  : "primary.light",
                color: dependencies.includes(dependency.name)
                  ? "white"
                  : "primary.contrastText",
                fontSize: "0.75rem",
                height: "24px",
              }}
            />
          ))}
        </Box>
      </div>

      <div className="row mb-3">
        <div className="col">
          <label>Enter Width Array (comma separated):</label>
          <input
            type="text"
            className="form-control"
            value={widthInput}
            onChange={(e) => setWidthInput(e.target.value)}
            placeholder="e.g. 12,24,36,48"
          />
        </div>
        <div className="col">
          <label>Enter Height Array (comma separated):</label>
          <input
            type="text"
            className="form-control"
            value={heightInput}
            onChange={(e) => setHeightInput(e.target.value)}
            placeholder="e.g. 12,24,36,48"
          />
        </div>
      </div>

      <div>
        <button className="btn btn-primary" onClick={handleApplyArrays}>
          Apply Arrays
        </button>
      </div>

      {widthArray.length > 0 && heightArray.length > 0 && (
        <>
          <p className="mt-3">
            Matrix Size: {heightArray.length} x {widthArray.length}
          </p>
          <div className="table-responsive">
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th></th>
                  {widthArray.map((width, index) => (
                    <th key={index}>{width}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {heightArray.map((height, rowIndex) => (
                  <tr key={rowIndex}>
                    <td>{height}</td>
                    {widthArray.map((_, colIndex) => (
                      <td key={colIndex}>
                        <input
                          type="text"
                          className="form-control"
                          value={matrix[colIndex][rowIndex] || ""}
                          onChange={(e) =>
                            handleMatrixChange(
                              rowIndex,
                              colIndex,
                              e.target.value
                            )
                          }
                        />
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </>
      )}

      {widthArray.length > 0 && heightArray.length > 0 && (
        <div className="mt-3">
          <button className="btn btn-success me-2" onClick={handleSubmit}>
            Submit Data
          </button>
          <button className="btn btn-danger" onClick={handleClear}>
            Clear Matrix
          </button>
        </div>
      )}

      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbarSeverity}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default DynamicMatrixTable;
