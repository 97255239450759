export const productTypeOptions = [
  { value: "Fauxwood", label: '2" Faux wood' },
  { value: "Zebra_blinds", label: "Zebra Blinds" },
  { value: "Roller_shades", label: "Roller Shades" },
  { value: "Vertical_blinds", label: "Vertical Blinds" },
  { value: "Materials", label: "Materials/Components" },
];

export const headrailOptions = {
  Zebra_blinds: [
    { value: "DBS-Headrail", label: "DBS-Headrail" },
    { value: "Progressive", label: "Progressive" },
    { value: "Brite-Blinds", label: "Brite Blinds" },
    { value: "Prog-Cordless", label: "Prog Cordless" },
  ],
  Roller_shades: [
    { value: "DBS-Headrail", label: "DBS-Headrail" },
    { value: "Surrey-Open-Roll", label: "Surrey Open Roll" },
    {
      value: "Surrey-Open-Roll-Cordless",
      label: "Surrey Open Roll Cordless",
    },
    { value: "Progressive", label: "Progressive" },
    { value: "Brite-Blinds", label: "Brite Blinds" },
    { value: "Prog-Cordless", label: "Prog Cordless" },
  ],
  Fauxwood: [{ value: "DBS-Headrail", label: "DBS-Headrail" }],
  Vertical_blinds: [{ value: "DBS-Headrail", label: "DBS-Headrail" }],
  Materials: [],
};
