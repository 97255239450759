import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { API_URI } from '../../constants/GlobalConstants';

const MatrixListing = () => {
  const [matrices, setMatrices] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  
  const userLogin = useSelector((state) => state.userSignIn);
  const { userInfo } = userLogin;

  useEffect(() => {
    const fetchMatrices = async () => {
      if (!userInfo?.token) {
        setError("Authorization required");
        setLoading(false);
        return;
      }

      try {
        const response = await axios.get(`${API_URI}/api/matrices`, {
          headers: {
            Authorization: `Bearer ${userInfo.token}`,
          },
        });
        setMatrices(response.data.data);
        setLoading(false);
      } catch (err) {
        setError('Failed to load matrices');
        setLoading(false);
      }
    };

    fetchMatrices();
  }, [userInfo]);

  if (loading) return <div>Loading matrices...</div>;
  if (error) return <div>{error}</div>;

  return (
    <div className="container mt-4">
      <h2>Matrix Listing</h2>
      {matrices.length === 0 ? (
        <p>No matrices found.</p>
      ) : (
        <div className="table-responsive">
          <table className="table table-bordered">
            <thead>
              <tr>
                <th>#</th>
                <th>Dependencies</th>
                <th>Width Array</th>
                <th>Height Array</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {matrices.map((matrix, index) => (
                <tr key={matrix._id}>
                  <td>{index + 1}</td>
                  <td>{matrix.productDependencies.join(', ')}</td>
                  <td>{matrix.widthArray.join(', ')}</td>
                  <td>{matrix.heightArray.join(', ')}</td>
                  <td>
                    <Link
                      to={`/matrix/view-matrix/${matrix._id}`}
                      className="btn btn-primary"
                    >
                      View
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default MatrixListing;
