import {
  IconButton,
  Tooltip,
  TextField,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { invParents } from "../../../constants/GlobalConstants";
import HeadrailConfig from "../config/HeadrailConfigs";

const ItemDetailsForm = ({ formData, handleChange }) => {
  const renderConfigComponent = () => {
    switch (formData.category) {
      case "Headrail":
        return (
          <HeadrailConfig formData={formData} handleChange={handleChange} />
        );
      default:
        return null;
    }
  };

  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-12 col-lg-8 px-4">
          <div
            className="card shadow-none bg-light border"
            style={{ padding: "20px", maxWidth: "100%" }}
          >
            <div className="card-body">
              <h5 className="card-title mb-4 text-success">Item Detail</h5>
              <div className="row g-3">
                {/* Item Category */}
                <div className="col-12">
                  <label className="form-label">
                    Item Category
                    <Tooltip
                      title={
                        <span style={{ fontSize: ".8rem" }}>
                          Select the{" "}
                          <span
                            style={{ color: "green" }}
                            className="text-success"
                          >
                            Category
                          </span>{" "}
                          to which the item belongs.
                        </span>
                      }
                      arrow
                    >
                      <IconButton size="small" sx={{ color: "#fff" }}>
                        <HelpOutlineIcon fontSize="inherit" />
                      </IconButton>
                    </Tooltip>
                  </label>
                  <select
                    className="form-select"
                    name="category"
                    value={formData.category}
                    onChange={handleChange}
                  >
                    <option></option>
                    {invParents.map((cat) => (
                      <option key={cat} value={cat}>
                        {cat}
                      </option>
                    ))}
                  </select>
                </div>

                {/* Conditionally render Item Name based on Category selection */}
                {formData.category && (
                  <div className="col-12">
                    <label className="form-label">
                      Item Name
                      <Tooltip
                        title={
                          <span style={{ fontSize: ".8rem" }}>
                            Enter the
                            <span className="text-success"> name </span> of the
                            item.
                          </span>
                        }
                        arrow
                      >
                        <IconButton size="small" sx={{ color: "#fff" }}>
                          <HelpOutlineIcon fontSize="inherit" />
                        </IconButton>
                      </Tooltip>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Item Name"
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                    />
                  </div>
                )}

                {formData.name && renderConfigComponent()}
                <div className="col-md-6 mt-4">
                  <label className="form-label">Deduction Method</label>
                  <select
                    className="form-control"
                    name="deductionMethod" 
                    value={formData.deductionMethod}
                    onChange={handleChange}
                  >
                    <option value="">Select Deduction Method</option>
                    <option value="width">Width</option>
                    <option value="height">Height</option>
                    <option value="sqft">Square Feet (sqft)</option>
                  </select>
                </div>
                {formData.sleeveOn ||
                formData.sleeveOff ||
                formData.category !== "Headrail" ? (
                  <div className="col-12">
                    <label className="form-label">
                      Item Remarks/Description
                      <Tooltip
                        title={
                          <span style={{ fontSize: ".8rem" }}>
                            Provide a{" "}
                            <span className="text-success">description</span> or
                            any <span className="text-success">remarks</span>{" "}
                            about the item.
                          </span>
                        }
                        arrow
                      >
                        <IconButton size="small" sx={{ color: "#fff" }}>
                          <HelpOutlineIcon fontSize="inherit" />
                        </IconButton>
                      </Tooltip>
                    </label>
                    <textarea
                      className="form-control"
                      placeholder="Specify the item and how it's been used"
                      rows={6}
                      cols={4}
                      name="des"
                      value={formData.des}
                      onChange={handleChange}
                    />
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ItemDetailsForm;
