import React, { useState, useEffect } from 'react';
import { Box, Chip, Grid, TextField, FormControl, CircularProgress, Alert } from '@mui/material';
import { Delete } from '@mui/icons-material';
import { useSelector, useDispatch } from 'react-redux';
import { createInvItemAction, resetCreateInvItem } from '../../../redux/actions/InventoryAction';
import { getProductTypesAction } from '../../../redux/actions/ProductTypeactions';

const AddCustomLiftHardwareModal = ({ show, handleClose }) => {
  const userLogin = useSelector((state) => state.userSignIn);
  const { userInfo } = userLogin;

  const dispatch = useDispatch();
  
  const productTypesList = useSelector((state) => state.productTypes);
  const { loading: productLoading, productTypes, error: productError } = productTypesList;

  const createInvItem = useSelector((state) => state.createInvItemReducer);
  const { loading, success, error } = createInvItem;

  const [customLiftHardwareName, setCustomLiftHardwareName] = useState('');
  const [dependencies, setDependencies] = useState([]);

  useEffect(() => {
    dispatch(getProductTypesAction(userInfo.token));
  }, [dispatch]);

  const handleAddDependency = (dependency) => {
    if (!dependencies.includes(dependency)) {
      setDependencies([...dependencies, dependency]);
    }
  };

  const handleRemoveDependency = (dependency) => {
    setDependencies(dependencies.filter((dep) => dep !== dependency));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    const liftHardwareData = {
      name: customLiftHardwareName,
      proDependency: dependencies,
      category: 'Lift_hardware',
      qtyPerStock: 0,
      remainingInStock: 0,
      stockAlert: 0,
      stockType: "box",
      totalStock: 0,
    };

    await dispatch(createInvItemAction(liftHardwareData, userInfo.token));
  };

  useEffect(() => {
    if (success) {
      setCustomLiftHardwareName('');
      setDependencies([]);
      handleClose();
      dispatch(resetCreateInvItem());
    }
  }, [success, handleClose]);

  if (!show) return null;

  return (
    <>
      {show && <div className="modal-backdrop fade show"></div>}
      <div className={`modal fade ${show ? 'show' : ''}`} tabIndex="-1" style={{ display: show ? 'block' : 'none' }}>
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Add Custom Lift Hardware</h5>
              <button type="button" className="btn-close" onClick={handleClose}></button>
            </div>
            <div className="modal-body">
              {loading && <CircularProgress />} {/* Show loader */}
              {error && <Alert severity="error">{error}</Alert>} {/* Show error */}
              {success && <Alert severity="success">Lift hardware added successfully!</Alert>} {/* Show success */}
              {productLoading && <CircularProgress />} {/* Show loader for product types */}
              {productError && <Alert severity="error">{productError}</Alert>} {/* Show error for product types */}

              <form onSubmit={handleSubmit}>
                {/* Lift Hardware Name Input */}
                <div className="mb-3">
                  <label htmlFor="liftHardwareName" className="form-label">Lift Hardware Name</label>
                  <input
                    type="text"
                    className="form-control"
                    id="liftHardwareName"
                    value={customLiftHardwareName}
                    onChange={(e) => setCustomLiftHardwareName(e.target.value)}
                    required
                  />
                </div>

                {/* Dependencies Selection */}
                <Grid container spacing={2}>
                  {/* Available Dependencies */}
                  <Grid item xs={12}>
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                      {productTypes.map((product) => (
                        <Chip
                          key={product._id}
                          label={product.name}
                          onClick={() => handleAddDependency(product.name)}
                          clickable
                          sx={{
                            bgcolor: 'primary.light',
                            color: 'primary.contrastText',
                          }}
                        />
                      ))}
                    </Box>
                  </Grid>

                  {/* Selected Dependencies */}
                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <TextField
                        label="Selected Dependencies"
                        value=""
                        InputProps={{
                          readOnly: true,
                          startAdornment: (
                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, paddingY: 2 }}>
                              {dependencies.map((dependency, index) => (
                                <Chip
                                  key={index}
                                  label={dependency}
                                  onDelete={() => handleRemoveDependency(dependency)}
                                  deleteIcon={<Delete />}
                                  sx={{
                                    bgcolor: 'primary.light',
                                    color: 'primary.contrastText',
                                  }}
                                />
                              ))}
                            </Box>
                          ),
                        }}
                      />
                    </FormControl>
                  </Grid>
                </Grid>

                <button type="submit" className="btn btn-primary mt-3">Add Lift Hardware</button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddCustomLiftHardwareModal;
