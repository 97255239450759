const InputTableHeader = ({ settingsData, headrailConfig }) => {
  const additionalColumns = headrailConfig?.formulas || [];

  return (
    <thead className="table-header-custom">
      <tr>
        <th className="border-end text-uppercase" style={{ width: "120px" }}>
          Index
        </th>
        <th className="border-end text-uppercase" style={{ width: "120px" }}>
          Width
        </th>
        <th className="border-end text-uppercase" style={{ width: "120px" }}>
          Height
        </th>
        {settingsData?.showLocation && (
          <th className="border-end text-uppercase" style={{ width: "150px" }}>
            Location
          </th>
        )}
        {settingsData?.showRemark && (
          <th className="border-end text-uppercase" style={{ width: "150px" }}>
            Remarks
          </th>
        )}
        {settingsData?.showControl && (
          <th className="border-end text-uppercase" style={{ width: "150px" }}>
            Control
          </th>
        )}
        {settingsData?.showMountLift && (
          <th className="border-end text-uppercase" style={{ width: "150px" }}>
            Lift
          </th>
        )}
        {settingsData?.showTile && (
          <th className="border-end text-uppercase" style={{ width: "150px" }}>
            Tile
          </th>
        )}
        {additionalColumns.map((column) => (
          <th
            key={column.key}
            className="border-end text-uppercase"
            style={{ width: "120px" }}
          >
            {column.key}
          </th>
        ))}
        <th className="border-end text-uppercase" style={{ width: "90px" }}>
          Sqft
        </th>
        <th className="border-end text-uppercase" style={{ width: "120px" }}>
          Add
        </th>
        <th className="border-end text-uppercase" style={{ width: "120px" }}>
          Delete
        </th>
      </tr>
    </thead>
  );
};

export default InputTableHeader;
