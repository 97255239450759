// This component deal with the view detailed order and
// edit / update the order
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import Badge from "../../components/layout/Badge";
import Header from "../../components/layout/Header";
import Loader from "../../components/layout/Loader";
import Sidebar from "../../components/layout/Sidebar";
import OrderDetail from "../../components/order/OrderDetail";
import {
  OrderDeleteAction,
  OrderDetailAction,
} from "../../redux/actions/OrderAction";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Fade from "@mui/material/Fade";
import { Divider, Tooltip } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { StyledDropDownMenu } from "../../theme/MUI/components/StyledTheme";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { red } from "@mui/material/colors";
import { Link } from "react-router-dom";

const DetailOrder = ({ nav }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const userLogin = useSelector((state) => state.userSignIn);
  const { error, userInfo } = userLogin;
  const detail = useSelector((state) => state.orderDetail);
  const { loading, order, success, error: ErrorDetail } = detail;
  const deleteOrder = useSelector((state) => state.orderDelete);
  const { loading: deleting, success: deleteSuccess } = deleteOrder;

  const [isEdit, setIsEdit] = useState(false);

  useEffect(() => {
    if (!userInfo) {
      navigate("/sign-in");
    }
    window.scrollTo(0, 0);
  }, [userInfo, navigate]);

  useEffect(() => {
    dispatch({ type: "ORDER_DELETE_RESET" });
    dispatch(OrderDetailAction(id, userInfo.token));
    window.scrollTo(0, 0);
  }, [dispatch, id]);

  const handleDelete = () => {
    handleClose();
    dispatch(OrderDeleteAction(id, userInfo.token));
    dispatch({ type: "ORDER_DELETE_RESET" });
  };

  const handleEdit = () => {
    setIsEdit(true);
    setAnchorEl(null);
  };

  //++ useState for th Action dropdown menu
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClickAction = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  console.log(order);

  return (
    <>
      <div className={nav.isSidebar}>
        <Header nav={nav} />
        <Sidebar nav={nav} />
        <main className="page-content">
        <div className="page-breadcrumb align-items-center mb-3">
        <div className="breadcrumb-title pe-3">Order Management</div>
            <div className="ps-3">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb mb-0 p-0">
                  <li className="breadcrumb-item">
                    <Link to="/order/list">
                      <i class="fadeIn animated bx bx-cart" />
                    </Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Order Detail
                  </li>
                </ol>
              </nav>
            </div>
            <div className="ms-auto">
              <div>
                <Tooltip title="">
                <div className="d-flex justify-content-end">
                    <Button
                      id="demo-customized-button"
                      aria-controls={open ? "demo-customized-menu" : undefined}
                      aria-haspopup="true"
                      aria-expanded={open ? "true" : undefined}
                      variant="contained"
                      disableElevation
                      onClick={handleClickAction}
                      endIcon={<KeyboardArrowDownIcon />}
                    >
                      Actions
                    </Button>
                    <StyledDropDownMenu
                      id="demo-customized-menu"
                      MenuListProps={{
                        "aria-labelledby": "demo-customized-button",
                      }}
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleClose}
                    >
                      <MenuItem
                        sx={{
                          fontWeight: "500",
                          color: "primary.main",
                        }}
                        disableRipple
                      >
                        Edit Action
                      </MenuItem>
                      {/* <MenuItem onClick={handleEdit} disableRipple>
                        <BorderColorIcon />
                        Edit Order
                      </MenuItem> */}
                      <Divider sx={{ my: 0.5 }} />
                      {(userInfo?.roles?.includes("admin") ||
                        userInfo?.isAdmin == true) && (
                        <>
                          <MenuItem
                            sx={{
                              fontWeight: "500",
                              color: "error.main",
                            }}
                            disableRipple
                          >
                            Critical Actions
                          </MenuItem>
                          <MenuItem
                            sx={{ color: "error.main" }}
                            onClick={handleDelete}
                            disableRipple
                          >
                            <DeleteForeverIcon />
                            Delete Order
                          </MenuItem>
                        </>
                      )}
                    </StyledDropDownMenu>
                  </div>
                </Tooltip>
              </div>
            </div>
          </div>
          {deleteSuccess ? (
            <Badge
              p={{
                type: "danger",
                message: "Order deleted",
                redirectName: "/Order List",
                redirect: "/order/list",
              }}
            />
          ) : success ? (
            <OrderDetail o={order} />
          ) : (
            <Loader />
          )}
        </main>
        <div className="overlay nav-toggle-icon" />
        {/* <a href="" className="back-to-top">
          <i className="bx bxs-up-arrow-alt" />
        </a> */}
      </div>
    </>
  );
};

export default DetailOrder;
