import { CREATE_PRODUCT_TYPE_FAIL, CREATE_PRODUCT_TYPE_REQUEST, CREATE_PRODUCT_TYPE_SUCCESS, PRODUCT_TYPES_FAIL, PRODUCT_TYPES_REQUEST, PRODUCT_TYPES_SUCCESS } from "../../constants/ProductTypeConstants";

export const createProductReducer = (state = {}, action) => {
    switch (action.type) {
        case 'PRODUCT_CREATE_REQUEST':
            return { loading: true }
        case 'PRODUCT_CREATE_SUCCESS':
            return { loading: false, success: true }
        case 'PRODUCT_CREATE_FAIL':
            return { loading: false, error: action.payload }
        default:
            return state;
    }
}

export const listAttributeReducer = (state = {}, action) => {
    switch (action.type) {
        case 'ATTRIBUTE_LIST_REQUEST':
            return { loading: true }
        case 'ATTRIBUTE_LIST_SUCCESS':
            return { loading: false, success: true, data: action.payload }
        case 'ATTRIBUTE_LIST_FAIL':
            return { loading: false, error: action.payload }
        default:
            return state;
    }
}

//product type reducers
export const productTypesReducer = (state = { productTypes: [] }, action) => {
    switch (action.type) {
      case PRODUCT_TYPES_REQUEST:
        return { loading: true, productTypes: [] };
      case PRODUCT_TYPES_SUCCESS:
        return { loading: false, productTypes: action.payload };
      case PRODUCT_TYPES_FAIL:
        return { loading: false, error: action.payload, productTypes:[] };
      default:
        return state;
    }
  };
  
  // Reducer for creating a product type
  export const createProductTypeReducer = (state = {}, action) => {
    switch (action.type) {
      case CREATE_PRODUCT_TYPE_REQUEST:
        return { loading: true };
      case CREATE_PRODUCT_TYPE_SUCCESS:
        return { loading: false, success: true, productType: action.payload };
      case CREATE_PRODUCT_TYPE_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };