import React from 'react';

const CustomKeyboard = ({ onKeyPress, onNext, onPrevious, onEnter, onClose, onClear,handleRecalculate,activeRowIndex }) => {
    const handleEnterPress = () => {
        if (handleRecalculate && typeof activeRowIndex === 'number') {
            handleRecalculate(activeRowIndex);
        }
        onEnter(); 
    };
    const handleNextPress = () => {
        if (handleRecalculate && typeof activeRowIndex === 'number') {
            handleRecalculate(activeRowIndex); 
        }
        onNext();
    };
    
    return (
        <div className="custom-keyboard">
            {/* Top Row */}
            <button className="close-button" onClick={onClose}>×</button>
            <div className="keyboard-row">
                {['1/8', '1/4', '3/8', '1/2', '5/8', '3/4', '7/8'].map((key) => (
                    <button key={key} className="keyboard-key" onClick={() => onKeyPress(key)}>
                        {key}
                    </button>
                ))}
            </div>

            {/* Second Row */}
            <div className="keyboard-row">
                {[1, 2, 3].map((key) => (
                    <button key={key} className="keyboard-key" onClick={() => onKeyPress(key)}>
                        {key}
                    </button>
                ))}
                <button className="keyboard-key special-key" onClick={handleNextPress}>
                    &#8594; {/* Right Arrow */}
                </button>
            </div>

            {/* Third Row */}
            <div className="keyboard-row">
                {[4, 5, 6].map((key) => (
                    <button key={key} className="keyboard-key" onClick={() => onKeyPress(key)}>
                        {key}
                    </button>
                ))}
                <button className="keyboard-key special-key" onClick={onPrevious}>
                    &#8592; {/* Left Arrow */}
                </button>
            </div>

            {/* Fourth Row */}
            <div className="keyboard-row">
                {[7, 8, 9].map((key) => (
                    <button key={key} className="keyboard-key" onClick={() => onKeyPress(key)}>
                        {key}
                    </button>
                ))}
                <button className="keyboard-key special-key" onClick={onClear}>
                    Del
                </button>
            </div>

            {/* Bottom Row */}
            <div className="keyboard-row">
                <button className="keyboard-key" onClick={() => onKeyPress(0)}>
                    0
                </button>
                <button className="keyboard-key special-key" onClick={handleEnterPress}>
                    Enter
                </button>
            </div>

            <style jsx>{`
                .custom-keyboard {
                    display: none; /* Hidden by default */
                    flex-direction: column;
                    background-color: #f0f0f0;
                    padding: 10px;
                    border-radius: 8px;
                    position: fixed;
                    bottom: 0;
                    width: 100%;
                    z-index: 1000;
                }
                .keyboard-row {
                    display: flex;
                    justify-content: space-around;
                    margin-bottom: 10px;
                }
                .keyboard-key {
                    flex: 1;
                    margin: 2px;
                    padding: 10px;
                    font-size: 16px;
                    background-color: #d1d1d1;
                    border: none;
                    border-radius: 5px;
                    cursor: pointer;
                    text-align: center;
                }
                .special-key {
                    background-color: #0088ff;
                    color: white;
                }
                .keyboard-key:hover {
                    background-color: #b0b0b0;
                }

                /* Display keyboard only on small screens */
                @media (max-width: 768px) {
                    .custom-keyboard {
                        display: flex;
                    }
                }
            `}</style>
        </div>
    );
};

export default CustomKeyboard;
