import { MATRIX_FAIL, MATRIX_REQUEST, MATRIX_SUCCESS } from "../../constants/MatrixConstants";


export const matrixReducer = (state = { matrix: {} }, action) => {
    switch (action.type) {
      case MATRIX_REQUEST:
        return { loading: true, matrix: {} };
      case MATRIX_SUCCESS:
        return { loading: false, matrix: action.payload };
      case MATRIX_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };