import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Header from "../../../components/layout/Header";
import Sidebar from "../../../components/layout/Sidebar";
import LoaderLin from "../../../components/layout/LoaderLin";
import Loader from "../../../components/layout/Loader";
import DealerOrdersChart from "../../../components/analytics/Charts/DealerOrdersChart";
import { AnnualSaleAnalAction } from "../../../redux/actions/AnalyticsActions";
import { ListDealerAction } from "../../../redux/actions/DealerAction";
import { toggleFullScreen } from "../../../components/layout/functions/layout";
import DealerSalesData from "../../../components/analytics/Charts/DealersSalesData";
import LineChart from '../../../components/analytics/sales/LineChart';


const SalesAnalytics = ({ nav }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userLogin = useSelector((state) => state.userSignIn);
  const { error, userInfo } = userLogin;
  const annualSaleAnal = useSelector((state) => state.annualSaleAnal);
  const { loading, data, success } = annualSaleAnal;
  const listDealer = useSelector((state) => state.listDealer);
  const { loading: loadingDealer, dealer, successDealer } = listDealer;

  const [dealerName, setDealerName] = useState("");

  useEffect(() => {
    if (!userInfo) {
      navigate("/sign-in");
    }
    window.scrollTo(0, 0);
    toggleFullScreen((nav = { nav }));
  }, [userInfo, navigate]);

  useEffect(() => {
    dispatch(ListDealerAction(userInfo.token));
    dispatch(
      AnnualSaleAnalAction({ companyName: dealerName, token: userInfo.token })
    );
  }, [dealerName]);
  

  return (
    <>
      <div className={nav.isSidebar}>
        <Header nav={nav} />
        <Sidebar nav={nav} />
        <main className="page-content container-fluid">
          <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
            <div className="breadcrumb-title pe-3">Dashboard</div>
            <div className="ps-3">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb mb-0 p-0">
                  <li className="breadcrumb-item">
                    <Link to="/">
                      <i className="bx bx-home-alt" />
                    </Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Sales Analytics
                  </li>
                </ol>
              </nav>
            </div>
          </div>
          <hr />
          <div className="row">
            <div className="col-lg-10 col-md-12 mx-auto">
              <div className="row mb-3">
                <div className="col-md-8">
                  <h6 className="mb-0">
                    <i className="fadeIn animated bx bx-line-chart" /> Annual
                    Sales by Dealer - Line Graph
                  </h6>
                </div>
                <div className="col-md-4">
                  {loadingDealer ? (
                    <LoaderLin />
                  ) : (
                    <select
                      className="form-select"
                      value={dealerName}
                      onChange={(e) => setDealerName(e.target.value)}
                    >
                      {!dealerName && <option>Inhouse</option>}
                      {dealer &&
                        dealer.map((c) => (
                          <option key={c._id} value={c._id}>
                            {c.companyName}
                          </option>
                        ))}
                    </select>
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12">
                  <div className="card p-3 shadow-sm mb-4">
                    {/* Chart for Annual Sales */}
                    {!data ? <Loader /> : <LineChart analData={data} />}
                  </div>
                </div>

                <div className="col-lg-12">
                  <div className="card p-3 shadow-sm mb-4">
                    {/* Dealer Orders Chart */}
                    <DealerSalesData />
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="card p-3 shadow-sm mb-4">
                    {/* Dealer Orders Chart */}
                    <DealerOrdersChart />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>

        <div className="overlay nav-toggle-icon" />
        <a href="#" className="back-to-top">
          <i className="bx bxs-up-arrow-alt" />
        </a>
      </div>
    </>
  );
};

export default SalesAnalytics;
