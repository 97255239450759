import React from "react";
import { Link, useParams } from "react-router-dom";
import Header from "../../components/layout/Header";
import Sidebar from "../../components/layout/Sidebar";
import EditInventoryItem from "../../components/inventoryManagement/EditInventoryItem";

const ViewInventoryItem = ({ nav }) => {
  const { id } = useParams();
  return (
    <>
      <div className={nav.isSidebar}>
        <Header nav={nav} />
        <Sidebar nav={nav} />
        {/*start content*/}
        <main className="page-content">
          <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
            <div className="breadcrumb-title pe-3">Inventory Management</div>
            <div className="ps-3">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb mb-0 p-0">
                  <li className="breadcrumb-item">
                    <Link to="/">
                      <i className="bx bx-home-alt" />
                    </Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/inventory/inventory-list">List Inventory</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    View Inventory Item
                  </li>
                </ol>
              </nav>
            </div>
          </div>
          <EditInventoryItem inventoryId={id} />
        </main>
      </div>
    </>
  );
};

export default ViewInventoryItem;
