import { USER_SETTINGS_UPDATE_FAIL, USER_SETTINGS_UPDATE_REQUEST, USER_SETTINGS_UPDATE_SUCCESS } from "../../constants/UserSettingsConstants";


export const userSettingsReducer = (state = { settings: {} }, action) => {
    switch (action.type) {
      case "SETTINGS_REQUEST":
        return { loading: true, ...state };
      case "SETTINGS_SUCCESS":
        return { loading: false, settings: action.payload };
      case "SETTINGS_FAIL":
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };
  

  
  export const userSettingsUpdateReducer = (state = { settings: {} }, action) => {
    switch (action.type) {
      case USER_SETTINGS_UPDATE_REQUEST:
        return { loading: true };
      case USER_SETTINGS_UPDATE_SUCCESS:
        return { loading: false, success: true, settings: action.payload };
      case USER_SETTINGS_UPDATE_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };