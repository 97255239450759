import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Bar, Doughnut } from 'react-chartjs-2';
import Header from '../../../components/layout/Header';
import Sidebar from '../../../components/layout/Sidebar';
import ProductionBarByMonth from '../../../components/analytics/production/ProductionBarByMonth';
import Loader from '../../../components/layout/Loader';
import { API_URI } from '../../../constants/GlobalConstants';

const ProductionAnalytics = ({ nav }) => {
  const [productionData, setProductionData] = useState(null);
  const [loading, setLoading] = useState(true);

  const userLogin = useSelector((state) => state.userSignIn);
  const { userInfo } = userLogin;
  const navigate = useNavigate();

  useEffect(() => {
    if (!userInfo) {
      navigate('/sign-in');
    } else {
      fetchProductionData();
    }
    window.scrollTo(0, 0);
  }, [userInfo, navigate]);

  const fetchProductionData = async () => {
    try {
      const { data } = await axios.get(`${API_URI}/api/analytics/production-data`, {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      });
      setProductionData(data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching production data:', error);
      setLoading(false);
    }
  };

  // Preparing data for the charts
  const dealerWiseData = {
    labels: Object.keys(productionData?.dealerWiseData || {}),
    datasets: [
      {
        label: 'Order Count',
        data: Object.values(productionData?.dealerWiseData || {}).map((data) => data.orderCount),
        backgroundColor: 'rgba(75, 192, 192, 0.6)',
        borderColor: 'rgba(75, 192, 192, 1)',
        borderWidth: 1,
      },
      {
        label: 'Product Count',
        data: Object.values(productionData?.dealerWiseData || {}).map((data) => data.productCount),
        backgroundColor: 'rgba(153, 102, 255, 0.6)',
        borderColor: 'rgba(153, 102, 255, 1)',
        borderWidth: 1,
      },
    ],
  };

  const productOrderCountData = {
    labels: Object.keys(productionData?.productOrderCount || {}),
    datasets: [
      {
        data: Object.values(productionData?.productOrderCount || {}),
        backgroundColor: [
          '#FF6384',
          '#36A2EB',
          '#FFCE56',
          '#4BC0C0',
          '#9966FF',
          '#FF9F40',
        ],
        hoverOffset: 4,
      },
    ],
  };

  return (
    <div className={nav.isSidebar}>
      <Header nav={nav} />
      <Sidebar nav={nav} />
      <main className="page-content">
        <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
          <div className="breadcrumb-title pe-3">Dashboard</div>
          <nav aria-label="breadcrumb" className="ps-3">
            <ol className="breadcrumb mb-0 p-0">
              <li className="breadcrumb-item">
                <Link to="/">
                  <i className="bx bx-home-alt" />
                </Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Production Analytics
              </li>
            </ol>
          </nav>
        </div>
        <hr />
        <div className="row">
          <div className="col-xl-12 mx-auto">
            <h6 className="mb-0">
              <i className="fadeIn animated bx bx-line-chart" />
              Production analytics - Last 6 Months
            </h6>
            <hr />
            {loading ? (
              <Loader />
            ) : (
              <>
                <div className="mb-4">
                  <h6><strong>Most Ordered Product:</strong> {productionData?.mostOrderedProduct || 'N/A'}</h6>
                </div>

                <ProductionBarByMonth
                  monthlyOrderData={productionData?.monthlyOrderData}
                />

                <hr />

                {/* Dealer-wise Orders Chart */}
                <div className="dealer-analytics">
                  <h6>Dealer-wise Orders and Product Count:</h6>
                  <Bar
                    data={dealerWiseData}
                    options={{
                      responsive: true,
                      plugins: {
                        legend: {
                          position: 'top',
                        },
                        title: {
                          display: true,
                          text: 'Dealer-wise Orders and Product Count',
                        },
                      },
                    }}
                  />
                </div>

                <hr />

                {/* Product Type Order Count Doughnut Chart */}
                <div className="product-type-analytics">
                  <h6>Product Type Order Count:</h6>
                  <div className="d-flex justify-content-center">
                    <div className="chart-container" style={{ width: '500px', height: '500px' }}>
                      <Doughnut
                        data={productOrderCountData}
                        options={{
                          responsive: true,
                          plugins: {
                            legend: {
                              position: 'right',
                            },
                            tooltip: {
                              callbacks: {
                                label: function (tooltipItem) {
                                  const label = tooltipItem.label || '';
                                  const value = tooltipItem.raw || 0;
                                  return `${label}: ${value} orders`;
                                },
                              },
                            },
                            title: {
                              display: true,
                              text: 'Product Type Order Count',
                            },
                          },
                        }}
                      />
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </main>
    </div>
  );
};

export default ProductionAnalytics;
