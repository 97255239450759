export const createDealerOrderReducer = (state = {}, action) => {
    switch (action.type) {
        case 'DEALER_ORDER_CREATE_REQUEST':
            return { loading: true, order: [] }
        case 'DEALER_ORDER_CREATE_SUCCESS':
            return { loading: false, success: true, data: action.payload }
        case 'DEALER_ORDER_CREATE_FAIL':
            return { loading: false, error: action.payload }
        case 'DEALER_ORDER_CREATE_RESET':
            return {}
        default:
            return state;
    }
}

export const ListOrdersReducer = (state = {}, action) => {
    switch (action.type) {
        case 'ORDER_LISTS_REQUEST':
            return { loading: true, actions: [] }
        case 'ORDER_LISTS_SUCCESS':
            return { loading: false, success: true, orders: action.payload }
        case 'ORDER_LISTS_FAIL':
            return { loading: false, error: action.payload }
        case 'ORDER_LISTS_CLEAR':
            return {};
        default:
            return state;
    }
}

export const OrderDetailReducer = (state = { order: {} }, action) => {
    switch (action.type) {
        case 'ORDER_DETAIL_REQUEST':
            return { loading: true, order: {} }
        case 'ORDER_DETAIL_SUCCESS':
            return { loading: false, order: action.payload, success: true }
        case 'ORDER_DETAIL_FAIL':
            return { loading: false, error: action.payload }
        default:
            return state;
    }
}

export const OrderDeleteReducer = (state = {}, action) => {
    switch (action.type) {
        case 'ORDER_DELETE_REQUEST':
            return { loading: true, }
        case 'ORDER_DELETE_SUCCESS':
            return { loading: false, success: true }
        case 'ORDER_DELETE_FAIL':
            return { loading: false, error: action.payload }
        case 'ORDER_DELETE_RESET':
            return {}
        default:
            return state;
    }
}

export const OrderByDealerIDReducer = (state = {}, action) => {
    switch (action.type) {
        case 'ORDER_BY_DEALER_ID_REQUEST':
            return { loading: true, actions: [] }
        case 'ORDER_BY_DEALER_ID_SUCCESS':
            return { loading: false, success: true, orderDetail: action.payload }
        case 'ORDER_BY_DEALER_ID_FAIL':
            return { loading: false, error: action.payload }
        default:
            return state;
    }
}

export const UpdateOrderReducer = (state = {}, action) => {
    switch (action.type) {
        case 'ORDER_UPDATE_REQUEST':
            return { loading: true, actions: [] }
        case 'ORDER_UPDATE_SUCCESS':
            return { loading: false, success: true, data: action.payload }
        case 'ORDER_UPDATE_FAIL':
            return { loading: false, error: action.payload }
        case 'ORDER_UPDATE_RESET':
            return {}
        default:
            return state;
    }
}


//=======================Public==============================
export const OrderCreateReducer = (state = {}, action) => {
    switch (action.type) {
        case 'ORDER_CREATE_REQUEST':
            return { loading: true, order: [] }
        case 'ORDER_CREATE_SUCCESS':
            return { loading: false, success: true }
        case 'ORDER_CREATE_FAIL':
            return { loading: false, error: action.payload }
        default:
            return state;
    }
}