import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Checkbox, FormControlLabel } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { changeUserSettings, fetchSettingsAction } from '../../../redux/actions/UserSettingsActions';

const SettingsModal = ({ show, onClose }) => {
  const dispatch = useDispatch();

  // Fetch user settings from the global state
  const settings = useSelector((state) => state.userSettings);
  const { settings: settingsData } = settings;
  const userLogin = useSelector((state) => state.userSignIn);
  const { userInfo } = userLogin;
  const { loading, error: updatedError, success } = useSelector((state) => state.updateduserSettings);

  const [remark, setRemark] = useState(false);
  const [location, setLocation] = useState(false);
  const [control, setControl] = useState(false);
  const [mountLift, setMountLift] = useState(false);
  const [tile,setTile]=useState(false)

  useEffect(() => {
    if (settingsData) {
      setRemark(settingsData.showRemark);
      setLocation(settingsData.showLocation);
      setControl(settingsData.showControl);
      setMountLift(settingsData.showMountLift);
      setTile(settingsData.showTile)
    }
  }, [settingsData]);

  const handleSave = () => {
    const updatedSettings = {
      showRemark: remark,
      showLocation: location,
      showControl: control,
      showMountLift: mountLift,
      showTile:tile
    };

    dispatch(changeUserSettings(userInfo.token, updatedSettings));
    onClose(); 
  };

  return (
    <Dialog open={show} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>User Settings</DialogTitle>
      <DialogContent>
        <FormControlLabel
          control={<Checkbox checked={remark} onChange={(e) => setRemark(e.target.checked)} />}
          label="Show Remark"
        />
        <FormControlLabel
          control={<Checkbox checked={location} onChange={(e) => setLocation(e.target.checked)} />}
          label="Show Location"
        />
        <FormControlLabel
          control={<Checkbox checked={control} onChange={(e) => setControl(e.target.checked)} />}
          label="Show Control"
        />
        <FormControlLabel
          control={<Checkbox checked={mountLift} onChange={(e) => setMountLift(e.target.checked)} />}
          label="Show Mount Lift"
        />
          <FormControlLabel
          control={<Checkbox checked={tile} onChange={(e) => setTile(e.target.checked)} />}
          label="Show Tile"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Close
        </Button>
        <Button onClick={handleSave} color="primary" disabled={loading}>
          Save Changes
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SettingsModal;
