import React, { useEffect, useState } from "react";
import {
  Modal,
  Box,
  Typography,
  Button,
  TextField,
  CircularProgress,
  Alert,
} from "@mui/material";
import PropTypes from "prop-types";
import axios from "axios";
import { API_URI } from "../../../constants/GlobalConstants";
import { useParams } from "react-router";
import { ViewDealerAction } from "../../../redux/actions/DealerAction";
import { useDispatch, useSelector } from "react-redux";
import fileDownload from "js-file-download";

function SendInvoiceModal({ open, onClose, selectedOrderId, userInfo }) {
  const [clientInfo, setClientInfo] = useState({
    email: "",
    name: "",
    salesPerson: "",
    phone: "",
    date: new Date().toISOString().slice(0, 10),
  });

  const [loading, setLoading] = useState(false);
  const [pdfLoading, setPdfLoading] = useState(false); // State for PDF loader
  const [error, setError] = useState(null); // State for error handling
  const {
    loading: dealerLoading,
    dealer,
    success: detailSuccess,
  } = useSelector((state) => state.viewDealer);
  const [pdfUrl, setPdfUrl] = useState(null);

  const dispatch = useDispatch();
  const { id } = useParams();

  useEffect(() => {
    if (id && userInfo.token) {
      dispatch(ViewDealerAction(id, userInfo.token));
    }
  }, [dispatch, id, userInfo.token]);

  useEffect(() => {
    if (detailSuccess && dealer) {
      setClientInfo({
        email: dealer.email || "",
        name: dealer.name || "",
        salesPerson: "",
        phone: dealer.phone || "",
        date: new Date().toISOString().slice(0, 10),
      });
    }
  }, [detailSuccess, dealer]);

  useEffect(() => {
    const fetchInvoicePdf = async () => {
      if (open && selectedOrderId) {
        try {
          setPdfLoading(true); // Start PDF loading
          const res = await axios.get(`${API_URI}/api/app/invoice-generator`, {
            responseType: "blob",
            headers: {
              Authorization: `Bearer ${userInfo.token}`,
            },
            params: {
              orderIds: selectedOrderId,
              clientInfo: JSON.stringify(clientInfo),
            },
          });

          const pdfBlobUrl = URL.createObjectURL(
            new Blob([res.data], { type: "application/pdf" })
          );
          setPdfUrl(pdfBlobUrl);
        } catch (error) {
          console.error("Error generating invoice:", error);
          setError("Failed to generate PDF.");
        } finally {
          setPdfLoading(false); 
        }
      }
    };

    fetchInvoicePdf();
  }, [open]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setClientInfo((prev) => ({ ...prev, [name]: value }));
  };

  const handleSendInvoice = async () => {
    setLoading(true);
    setError(null);
    try {
      // Send the invoice data
      const response = await axios.post(
        `${API_URI}/api/app/invoice-get-data`,
        { orderIds: selectedOrderId, clientInfo },
        {
          headers: {
            Authorization: `Bearer ${userInfo.token}`,
          },
        }
      );
  
      // Check if response is successful
      if (response.status === 200) {
        // Download the PDF file if the API call succeeds
        if (pdfUrl) {
          const pdfBlob = await fetch(pdfUrl).then((res) => res.blob());
          fileDownload(
            pdfBlob,
            `Invoice-${clientInfo.name}-${new Date().toISOString().slice(0, 10)}.pdf`
          );
        }
        // Only close modal if the process is successful
        onClose();
      } else {
        throw new Error("Failed to generate invoice data.Check app password and email");
      }
    } catch (error) {
      console.error("Error sending invoice data:", error);
      setError("Failed to send the invoice.please check app email and password and try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          ...style,
          width: "90vw",
          height: "90vh",
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "row",
        }}
        className="modal-lg"
      >
        {/* Left side: Form fields */}
        <Box
          sx={{
            width: "45%",
            height: "100%", 
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h6" component="h2" sx={{ color: "black" }}>
            Send Invoice
          </Typography>
          <TextField
            fullWidth
            label="Email"
            name="email"
            value={clientInfo.email}
            onChange={handleChange}
            sx={{ mt: 2 }}
          />
          <TextField
            fullWidth
            label="Client Name"
            name="name"
            value={clientInfo.name}
            onChange={handleChange}
            sx={{ mt: 2 }}
          />
          <TextField
            fullWidth
            label="Salesperson"
            name="salesPerson"
            value={clientInfo.salesPerson}
            onChange={handleChange}
            sx={{ mt: 2 }}
          />
          <TextField
            fullWidth
            label="Phone Number"
            name="phone"
            value={clientInfo.phone}
            onChange={handleChange}
            sx={{ mt: 2 }}
          />
          <TextField
            fullWidth
            type="date"
            label="Date"
            name="date"
            value={clientInfo.date}
            onChange={handleChange}
            sx={{ mt: 2 }}
          />

          {error && (
            <Alert severity="error" sx={{ mt: 2 }}>
              {error}
            </Alert>
          )}

          <Box sx={{ mt: 3, display: "flex", justifyContent: "flex-end" }}>
            <Button onClick={onClose} sx={{ mr: 2 }}>
              Cancel
            </Button>
            <Button
              onClick={handleSendInvoice}
              disabled={loading}
              variant="contained"
            >
              {loading ? <CircularProgress size={24} /> : "Send"}
            </Button>
          </Box>
        </Box>

        {/* Right side: PDF Preview with loader */}
        <Box
          sx={{
            width: "50%",
            height: "100%", 
            pl: 3,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {pdfLoading ? (
            <CircularProgress size={50} /> 
          ) : (
            pdfUrl && (
              <iframe
                src={pdfUrl}
                title="Invoice PDF"
                style={{ width: "100%", height: "100%", border: "none" }}
              ></iframe>
            )
          )}
        </Box>
      </Box>
    </Modal>
  );
}

SendInvoiceModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  selectedOrderId: PropTypes.number.isRequired,
  userInfo: PropTypes.object.isRequired,
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
};

export default SendInvoiceModal;
