import { getDecimal, getFinalVal, nearestGreaterVal, totalDecimal } from "../GolbalFunctions"
// import { heightArray, widthArray, wMatrix } from "./RollerMatrix"
import { evaluate } from 'mathjs';  


/**
 * Calculate dynamic headrail formulas only for properties that are specified in the formulas.
 * @param {Object[]} formulas - Array of formula objects from the headrail.
 * @param {Object} dimensions - The dimensions object containing width, height, etc.
 * @returns {Object} - The calculated values.
 */


const eighths = ['0', '1/8', '1/4', '3/8', '1/2', '5/8', '3/4', '7/8', '1'];
const eighthsValue = [0, 0.125, 0.25, 0.375, 0.5, 0.625, 0.75, 0.875, 1];

// Convert a floating-point value to its nearest fraction of 1/8
export function getFraction(val) {
    let closestIndex = 0;
    let minDifference = Math.abs(val - eighthsValue[0]);

    for (let i = 1; i < eighthsValue.length; i++) {
        const diff = Math.abs(val - eighthsValue[i]);
        if (diff < minDifference) {
            minDifference = diff;
            closestIndex = i;
        }
    }

    return eighths[closestIndex];
}

// Split the float value into whole number and fractional part
export function splitFraction(val) {
    return val.toString().split('.');
}

// Main function to return whole inches and fractions
export function getFinalValOfCalc(val) {
    const xVal = splitFraction(val);
    const wholeInches = xVal[0];
    const decimalPart = xVal[1] ? parseFloat('0.' + xVal[1]) : 0;
    const fraction = decimalPart ? getFraction(decimalPart) : '';

    return (wholeInches + (fraction && fraction !== '0' ? ' ' + fraction : ''));
}

// Function to calculate values based on formulas and dimensions
export function calculateHeadrailFormulas(formulas, dimensions) {
    const calculatedValues = {};

    formulas.forEach(formula => {
        const { key, operand, expression } = formula;
        const value = dimensions[operand]; // Get the value from dimensions (e.g., width, height)

        if (value !== undefined) {
            try {
                // Simplify the expression by removing whitespace
                const simplifiedExpression = expression.replace(/\s/g, '');

                // Check for trivial expressions involving 0 or 1
                if (
                    simplifiedExpression === `${operand}+0` || 
                    simplifiedExpression === `${operand}-0` || 
                    simplifiedExpression === `${operand}*1` || 
                    simplifiedExpression === `${operand}/1` ||
                    simplifiedExpression === `${operand}*0` || 
                    simplifiedExpression === `${operand}/0`
                ) {
                    // If the expression is like 'height + 0', 'width * 1', 'height * 0', etc.
                    calculatedValues[key] = getFinalValOfCalc(value);
                } else {
                    // Otherwise, evaluate the expression normally
                    const scope = { [operand]: value }; // Create scope with the operand and its value
                    const evaluatedValue = evaluate(expression, scope); // Evaluate the expression
                    const finalValue = getFinalValOfCalc(evaluatedValue);
                    calculatedValues[key] = finalValue; // Store the final value
                }
            } catch (error) {
                console.error(`Error evaluating formula for ${key}:`, error);
            }
        } else {
            console.error(`Value for operand '${operand}' not found in dimensions.`);
        }
    });

    return calculatedValues;
}
export function rollerEquation(w, h, headrailConfig, matrix) {
    const { heightArray, widthArray, matrix: matrixArray } = matrix;
    
    // Convert all values in matrixArray to numbers
    const numericMatrixArray = matrixArray.map(row => row.map(value => parseFloat(value)));

    const wFraction = totalDecimal(w);
    const hFraction = totalDecimal(h);
    const dimensions = { width: wFraction, height: hFraction };

    let calculatedValues = {};
    if (headrailConfig && headrailConfig.formulas) {
        calculatedValues = calculateHeadrailFormulas(headrailConfig.formulas, dimensions);
    }

    const wNearest = nearestGreaterVal(widthArray, wFraction);
    const hNearest = nearestGreaterVal(heightArray, hFraction);

    const widthIndex = widthArray.indexOf(wNearest);
    const heightIndex = heightArray.indexOf(hNearest);

    // Use the numeric matrix for sqft
    const sqft = numericMatrixArray[widthIndex][heightIndex];

    return {
        ...calculatedValues,  
        sqft  
    };
}