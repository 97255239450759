import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  createProductTypeAction,
  getProductTypesAction,
} from "../../../redux/actions/ProductTypeactions";
import LoaderLin from "../../layout/LoaderLin";
import {
  Container,
  Typography,
  TextField,
  Button,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  Box,
  IconButton,
  Alert,
  Card,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";

const ProductTypeComponent = () => {
  const dispatch = useDispatch();
  const userLogin = useSelector((state) => state.userSignIn);
  const { userInfo } = userLogin;
  const productTypesList = useSelector((state) => state.productTypes);
  const { loading, error, productTypes } = productTypesList;

  const [newProductType, setNewProductType] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  useEffect(() => {
    dispatch(getProductTypesAction(userInfo.token));
  }, [dispatch]);

  const createProductTypeHandler = async (e) => {
    e.preventDefault();
    await dispatch(createProductTypeAction({ name: newProductType },userInfo.token));
    setNewProductType("");
    setSnackbarMessage("Product type added successfully!");
    setSnackbarOpen(true);
    dispatch(getProductTypesAction(userInfo.token));
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };
  console.log(productTypes);
  return (
    <Container maxWidth="lg" className="my-4">
      <Typography
        variant="h5"
        gutterBottom
        align="center"
        style={{ color: "white" }}
      >
        Product Types
      </Typography>

      {loading ? (
        <Box display="flex" justifyContent="center" mb={2}>
          <LoaderLin />
        </Box>
      ) : error ? (
        <Alert severity="error" align="center">
          {error}
        </Alert>
      ) : (
        <>
          <form onSubmit={createProductTypeHandler} className="mt-4">
            <TextField
              fullWidth
              variant="outlined"
              label="Enter Product Type"
              value={newProductType}
              onChange={(e) => setNewProductType(e.target.value)}
              margin="normal"
              InputProps={{
                style: { color: "white" },
              }}
              InputLabelProps={{
                style: { color: "white" },
              }}
              placeholder="Enter Product Type"
            />
            <Box display="flex" justifyContent="flex-end" className="mt-2">
              <Button type="submit" variant="contained" color="primary">
                Add Product Type
              </Button>
            </Box>
          </form>

          <TableContainer component={Card} className="mt-4">
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell align="right">Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Array.isArray(productTypes) &&
                  productTypes?.map((type) => (
                    <TableRow key={type._id}>
                      <TableCell>{type._id}</TableCell>
                      <TableCell>{type.name}</TableCell>
                      <TableCell align="right">
                        <IconButton color="primary" aria-label="edit">
                          <EditIcon />
                        </IconButton>
                        <IconButton color="error" aria-label="delete">
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        message={snackbarMessage}
      />
    </Container>
  );
};

export default ProductTypeComponent;
