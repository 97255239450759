import React, { useEffect, useState } from "react";
import {
  Stepper,
  Step,
  StepLabel,
  Button,
  Chip,
  Box,
  FormControl,
  Container,
  useTheme,
  Grid,
  IconButton,
  TextField,
  MenuItem,
  InputLabel,
  Select,
} from "@mui/material";
import { Add, Delete } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { getInvItemsByCat } from "../../redux/actions/InventoryAction";
import axios from "axios";
import { API_URI } from "../../constants/GlobalConstants";
import { useNavigate } from "react-router";
import { Snackbar, Alert } from '@mui/material';

const ProdCalculation = () => {
  const theme = useTheme();
  const [activeStep, setActiveStep] = useState(0);
  const [headrail, setHeadrail] = useState("");
  const [dependencies, setDependencies] = useState([]);
  const userLogin = useSelector((state) => state.userSignIn);
  const { userInfo } = userLogin;
  const inventoryItems = useSelector((state) => state.getInvItemsByCat);
  const [formulas, setFormulas] = useState([
    { customName: "", dimension: "", operation: "", deductionValue: "" },
  ]);
  const { data, loading, error: inventoryError } = inventoryItems;

  const [error, setError] = useState(null);
  const [openError, setOpenError] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false); 


  const dispatch = useDispatch();
  const navigate = useNavigate();

  const steps = ["Headrail Select", "Product Dependency", "Calculations"];

  
  useEffect(() => {
    if (userInfo?.token) {
      dispatch(getInvItemsByCat("Headrail", userInfo.token));
    }
  }, [userInfo.token, dispatch]);

  const handleNext = () => setActiveStep((prev) => prev + 1);
  const handleBack = () => setActiveStep((prev) => prev - 1);

  const availableDependencies = [
    "Zebra_blinds",
    "Roller_shades",
    "Fauxwood",
    "Vertical_blinds",
  ];
  const handleError = (errMessage) => {
    setError(errMessage);
    setOpenError(true);  
  };

  const handleCloseError = () => {
    setOpenError(false); 
  };

  const handleCloseSuccess = () => {
    setOpenSuccess(false);  
  };

  const handleAddDependency = (dependency) => {
    if (!dependencies.includes(dependency)) {
      setDependencies([...dependencies, dependency]);
    }
  };

  const handleRemoveDependency = (dependency) => {
    setDependencies((prev) => prev.filter((item) => item !== dependency));
  };

  const handleAddFormula = () => {
    setFormulas([
      ...formulas,
      { customName: "", dimension: "", operation: "", deductionValue: "" },
    ]);
  };

  
  const handleRemoveFormula = (index) => {
    const newFormulas = [...formulas];
    newFormulas.splice(index, 1);
    setFormulas(newFormulas);
  };

  const handleUpdateFormula = (index, field, value) => {
    const updatedFormulas = formulas.map((item, i) =>
      i === index ? { ...item, [field]: value } : item
    );
    setFormulas(updatedFormulas);
  };

  const handleSaveFormulas = async () => {
    try {
      await axios.post(
        `${API_URI}/api/inventory/add-calculation`,
        {
          inventoryId: headrail.id,
          formulas: formulas.map((formula) => ({
            customName: formula.customName.toLowerCase(),
            dimension: formula.dimension,
            expression: `${formula.dimension} ${formula.operation} ${formula.deductionValue}`,
          })),
          dependencies,
        },
        {
          headers: {
            Authorization: `Bearer ${userInfo.token}`,
          },
        }
      );
      console.log("Formulas saved successfully");
  
      setHeadrail("");
      setDependencies([]);
      setFormulas([
        { customName: "", dimension: "", operation: "", deductionValue: "" },
      ]);
      setActiveStep(0);
      setOpenSuccess(true);
      // navigate("/inventory/inventory-list");
    } catch (error) {
      console.error("Error saving formulas:", error);
      const errMsg = error.response?.data?.message || 'Something went wrong';
      handleError(errMsg); 
    }
  };
  
  const renderStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <TextField
                  label="Headrail Type"
                  value={headrail}
                  onChange={(e) => setHeadrail(e.target.value)}
                  select
                  fullWidth
                >
                  {loading ? (
                    <MenuItem value="" disabled>
                      Loading...
                    </MenuItem>
                  ) : inventoryError ? (
                    <MenuItem value="" disabled>
                      Error loading data
                    </MenuItem>
                  ) : (
                    data?.[0]?.items?.map((item, index) => (
                      <MenuItem key={index} value={item}>
                        {item.name}
                      </MenuItem>
                    ))
                  )}
                </TextField>
              </FormControl>
            </Grid>
          </Grid>
        );
      case 1:
        return (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
                {availableDependencies.map((dependency) => (
                  <Chip
                    key={dependency}
                    label={dependency}
                    onClick={() => handleAddDependency(dependency)}
                    clickable
                    sx={{
                      bgcolor: theme.palette.primary.light,
                      color: theme.palette.getContrastText(
                        theme.palette.primary.light
                      ),
                    }}
                  />
                ))}
              </Box>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <TextField
                  label="Selected Dependencies"
                  value=""
                  InputProps={{
                    readOnly: true,
                    startAdornment: (
                      <Box
                        sx={{
                          display: "flex",
                          flexWrap: "wrap",
                          gap: 1,
                          paddingY: 2,
                        }}
                      >
                        {dependencies.map((dependency, index) => (
                          <Chip
                            key={index}
                            label={dependency}
                            onDelete={() => handleRemoveDependency(dependency)}
                            deleteIcon={<Delete />}
                            sx={{
                              bgcolor: theme.palette.primary.light,
                              color: theme.palette.getContrastText(
                                theme.palette.primary.light
                              ),
                            }}
                          />
                        ))}
                      </Box>
                    ),
                  }}
                />
              </FormControl>
            </Grid>
          </Grid>
        );
      case 2:
        return (
          <Grid container spacing={2}>
            {formulas.map((formula, index) => (
              <Grid
                container
                spacing={2}
                key={index}
                sx={{ mb: 2, mt: 2, ml: 2, alignItems: "center" }}
              >
                <Grid item xs={12} sm={3}>
                  <TextField
                    label="Component Name"
                    value={formula.customName}
                    onChange={(e) =>
                      handleUpdateFormula(index, "customName", e.target.value)
                    }
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <FormControl fullWidth>
                    <InputLabel>Dimension</InputLabel>
                    <Select
                      value={formula.dimension}
                      onChange={(e) =>
                        handleUpdateFormula(index, "dimension", e.target.value)
                      }
                      fullWidth
                    >
                      <MenuItem value="width">Width</MenuItem>
                      <MenuItem value="height">Height</MenuItem>
                      {/* Add other dimensions as needed */}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <FormControl fullWidth>
                    <InputLabel>Operation</InputLabel>
                    <Select
                      value={formula.operation}
                      onChange={(e) =>
                        handleUpdateFormula(index, "operation", e.target.value)
                      }
                      fullWidth
                    >
                      <MenuItem value="+">Add</MenuItem>
                      <MenuItem value="-">Subtract</MenuItem>
                      <MenuItem value="*">Multiply</MenuItem>
                      <MenuItem value="/">Divide</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={2}>
                  <TextField
                    label="Deduction Value"
                    value={formula.deductionValue}
                    onChange={(e) =>
                      handleUpdateFormula(
                        index,
                        "deductionValue",
                        e.target.value
                      )
                    }
                    fullWidth
                    type="number"
                  />
                </Grid>
                <Grid item xs={12} sm={1}>
                  <IconButton
                    onClick={() => handleRemoveFormula(index)}
                    aria-label="delete"
                  >
                    <Delete />
                  </IconButton>
                </Grid>
              </Grid>
            ))}
            <Grid item xs={12} sx={{ mt: 2 }}>
              <Button
                variant="outlined"
                onClick={handleAddFormula}
                startIcon={<Add />}
              >
                Add Formula
              </Button>
            </Grid>
          </Grid>
        );
      default:
        return "Unknown step";
    }
  };

  return (
    <Container
      maxWidth="md"
      sx={{
        bgcolor: theme.palette.mode === "dark" ? "#424242" : "#fff",
        color: theme.palette.mode === "dark" ? "#fff" : "#000",
        p: 3,
        borderRadius: 2,
        minHeight: "60vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <Box>
        <Stepper
          activeStep={activeStep}
          sx={{
            width: "100%",
            overflowX: "auto",
            "& .MuiStep-root": {
              minWidth: "100px",
            },
            "& .MuiStepLabel-root": {
              color: theme.palette.text.primary,
            },
            "& .MuiStepIcon-root": {
              color: theme.palette.primary.main,
            },
          }}
        >
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <Box sx={{ mt: 2 }}>{renderStepContent(activeStep)}</Box>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
        <Button
          color="inherit"
          disabled={activeStep === 0}
          onClick={handleBack}
          sx={{ mr: 1 }}
        >
          Back
        </Button>
        {activeStep === steps.length - 1 ? (
          <Button variant="contained" onClick={handleSaveFormulas}>
            Save Formulas
          </Button>
        ) : (
          <Button variant="contained" onClick={handleNext}>
            {activeStep === steps.length - 2 ? "Finish" : "Next"}
          </Button>
        )}
      </Box>
     {/* Snackbar for error */}
     <Snackbar open={openError} autoHideDuration={3000} onClose={handleCloseError}>
        <Alert onClose={handleCloseError} severity="error" sx={{ width: '100%' }}>
          {error}
        </Alert>
      </Snackbar>

      {/* Snackbar for success */}
      <Snackbar open={openSuccess} autoHideDuration={3000} onClose={handleCloseSuccess}>
        <Alert onClose={handleCloseSuccess} severity="success">
          Formulas saved successfully!
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default ProdCalculation;
