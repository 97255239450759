import { applyMiddleware, combineReducers, createStore } from 'redux'
import thunk from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension'
import {
    AppointCreateReducer, AppointDeleteReducer, AppointDetailReducer,
    AppointListReducer, AppointUpdateReducer, ListCompletedTaskReducer,
    ListInquiresReducer, ListPaymentPendingReducer, ListQuotationReducer,
    OrderListReducer
} from './redux/reducers/AppointReducers'
import { UserSignInReducer } from './redux/reducers/userReducers'
import { ClientCreateReducer, ClientListReducer } from './redux/reducers/ClientReducer'
import balanceReducer, { DealerCreateReducer, DealerListReducer, DealerOrderReducer, DealerOrderStatsReducer, UpdateDealerNotesReducer, ViewDealerReducer, dealerUpdateRatesReducer, dealerUpdateReducer } from './redux/reducers/DealerReducer'
import {
    createDealerOrderReducer,
    ListOrderReducer, ListOrdersReducer, OrderByDealerIDReducer, OrderCreateReducer, OrderDeleteReducer, OrderDetailReducer, UpdateOrderReducer
} from './redux/reducers/OrderReducer'
import { AnnualSaleAnalReducer } from './redux/reducers/AnalyticsReducers'
import calculationReducer, { createInvItemReducer, getFabricItemsReducer, getInvItemsByCatReducer, getInventoryTableData, liftStyleReducer } from './redux/reducers/InventoryReducer'
import { createProductReducer, createProductTypeReducer, listAttributeReducer, productTypesReducer } from './redux/reducers/ProductReducers'
import { createUserReducer, updateUserReducer, userListReducer } from './redux/reducers/userManageReducer'
import {chartReducer, dashboardReducer} from './redux/reducers/DashboardReducer'
import { transactionListReducer } from './redux/reducers/TransactionReducers'
import { userSettingsReducer, userSettingsUpdateReducer } from './redux/reducers/UserSettingsReducers'
import { matrixReducer } from './redux/reducers/MatrixReducers'



const reducer = combineReducers({
    listAppointment: AppointListReducer,
    listOrder: OrderListReducer,
    listCompletedTask: ListCompletedTaskReducer,
    ListPaymentPending: ListPaymentPendingReducer,
    ListQuotation: ListQuotationReducer,
    ListInquiries: ListInquiresReducer,
    appointCreate: AppointCreateReducer,
    appointDetail: AppointDetailReducer,
    appointUpdate: AppointUpdateReducer,
    appointDelete: AppointDeleteReducer,

    orderList: ListOrdersReducer,
    orderDetail: OrderDetailReducer,
    orderDelete: OrderDeleteReducer,
    orderDealerCreate: createDealerOrderReducer,
    OrderByDealerID: OrderByDealerIDReducer,
    UpdateOrder: UpdateOrderReducer,

    createClient: ClientCreateReducer,
    clientList: ClientListReducer,

    createDealer: DealerCreateReducer,
    listDealer: DealerListReducer,
    viewDealer: ViewDealerReducer,
    dealerOrderStats: DealerOrderStatsReducer,
    updateDealerNotes: UpdateDealerNotesReducer,
    dealerOrder:DealerOrderReducer,
    outStandingBalance:balanceReducer,
    dealerUpdate: dealerUpdateReducer,
    dealerUpdateRates: dealerUpdateRatesReducer, 


    //dashboard data
    dashboard: dashboardReducer ,
    chart:chartReducer,
    //Transaction data
    transactions:transactionListReducer,
    annualSaleAnal: AnnualSaleAnalReducer,



    createProduct: createProductReducer,
    listAttribute: listAttributeReducer,
    //inventory data
    createInvItemReducer,
    getInvItemsByCat: getInvItemsByCatReducer,
    fabricItems: getFabricItemsReducer,
    liftSyleItems:liftStyleReducer,
    //product type data
    productTypes: productTypesReducer,
    createProductType: createProductTypeReducer,
    //matrix data
    matrix: matrixReducer,

    inventorytabledata:getInventoryTableData,
    userList: userListReducer,
    updatedUser:updateUserReducer,
    createUser: createUserReducer,

    //user setting data
    userSettings:userSettingsReducer,
    updateduserSettings:userSettingsUpdateReducer,
    //calculation data
    inventoryCalculation:calculationReducer,

    userSignIn: UserSignInReducer,
    //------PUBLIC Routes-----------
    orderCreate: OrderCreateReducer,
})

//Getting the data from local storage
const userInfoFromStorage = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : null

const initialState = {
    userSignIn: { userInfo: userInfoFromStorage },
}

const middleware = [thunk]

const store = createStore(reducer, initialState, composeWithDevTools(applyMiddleware(...middleware)))

export default store