import {
  getFinalVal,
  nearestGreaterVal,
  totalDecimal,
} from "../GolbalFunctions";
import { calculateHeadrailFormulas } from "../rollerShades/RollerProduction";
// import { heightArray, wMatrix, widthArray } from "./verticalMatrix";

export function verticalProduction(w, h, headrailConfig, matrix) { 
  const { heightArray, widthArray, matrix: matrixArray } = matrix;
  
  // Convert all values in matrixArray to numbers
  const numericMatrixArray = matrixArray.map(row => row.map(value => parseFloat(value)));

  const wDecimal = totalDecimal(w);
  const hDecimal = totalDecimal(h);
  const wNearest = nearestGreaterVal(widthArray, wDecimal);
  const hNearest = nearestGreaterVal(heightArray, hDecimal);

  const dimensions = { width: wDecimal, height: hDecimal };

  let calculatedValues = {};
  if (headrailConfig && headrailConfig.formulas) {
    calculatedValues = calculateHeadrailFormulas(
      headrailConfig.formulas,
      dimensions
    );
  }

  const widthIndex = widthArray.indexOf(wNearest);
  const heightIndex = heightArray.indexOf(hNearest);

  // Use the numeric matrix for vfullPrice
  const vfullPrice = numericMatrixArray[widthIndex][heightIndex];

  return {
    ...calculatedValues,
    vfullPrice,
  };
}
