import axios from "axios";
import React, { useState } from "react";
import fileDownload from "js-file-download";

import Header from "../../components/layout/Header";
import Sidebar from "../../components/layout/Sidebar";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { TextField } from "@mui/material";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { API_URI } from "../../constants/GlobalConstants";
import { useSelector } from "react-redux";

const InvoiceApp = ({ nav }) => {
  const userLogin = useSelector((state) => state.userSignIn);
  const { userInfo } = userLogin;
  const [name, setName] = useState("");
  const [salesPerson, setSalesPerson] = useState("");
  const [date, setDate] = useState(dayjs());
  const [des, setDes] = useState("");
  const [action, setAction] = useState("");
  const [products, setProducts] = useState([{ product: "", price: 0.0 }]);
  const [clientDetails, setClientDetails] = useState({
    clientName: "",
    clientEmail: "",
    clientPhone: "",
  });

  // Handle adding a new product
  const handleAddProduct = () => {
    setProducts([...products, { product: "", price: 0.0 }]);
  };

  // Handle removing a product
  const handleRemoveProduct = (index) => {
    const updatedProducts = products.filter((_, i) => i !== index);
    setProducts(updatedProducts);
  };

  // Handle updating product or price values
  const handleProductChange = (index, field, value) => {
    const updatedProducts = products.map((item, i) =>
      i === index ? { ...item, [field]: value } : item
    );
    setProducts(updatedProducts);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const val = {
      name,
      salesPerson,
      action,
      date: date.$d.toString().slice(4, 15),
      products, 
      des,
      clientDetails,
    };
    await axios.post(`${API_URI}/api/app/invoice-get-data`, val, {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    });
    const res = await axios.get(`${API_URI}/api/app/invoice-generator`, {
      responseType: "blob",
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    });

    await fileDownload(res.data, `DSSBlinds-${action}-${name}.pdf`);
  };

  const handleClientDetailsChange = (e) => {
    const { name, value } = e.target;
    setClientDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  return (
    <>
      <div className={nav.isSidebar}>
        <Header />
        <Sidebar nav={nav} />
        {/*start content*/}
        <main className="page-content">
          <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
            <div className="breadcrumb-title pe-3">Dashboard</div>
            <div className="ps-3">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb mb-0 p-0">
                  <li className="breadcrumb-item">
                    <a href="/">
                      <i className="bx bx-home-alt" />
                    </a>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Create Invoice
                  </li>
                </ol>
              </nav>
            </div>
          </div>

          <div className="row">
            <form onSubmit={handleSubmit}>
              <div className="col-lg-12 mx-auto">
                <div className="card">
                  <div className="card-body">
                    <div className="row g-3">
                      <h4>Invoice Details</h4>
                      <div className="col-lg-4 col-md-4">
                        <label className="form-label">Invoice/ to</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="To"
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                        />
                      </div>
                      <div className="col-lg-2 col-md-2">
                        <label className="form-label">Salesperson</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Salesperson"
                          value={salesPerson}
                          onChange={(e) => setSalesPerson(e.target.value)}
                        />
                      </div>
                      <div className="col-lg-2 col-md-2">
                        <label className="form-label">Action</label>
                        <select
                          className="form-select"
                          value={action}
                          onChange={(e) => setAction(e.target.value)}
                        >
                          <option value=""></option>
                          <option value="Estimation">Estimation</option>
                          <option value="Invoice">Invoice</option>
                        </select>
                      </div>
                      <div className="col-lg-4 col-md-4">
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <MobileDatePicker
                            label="Date"
                            inputFormat="MM/DD/YYYY"
                            value={date}
                            onChange={(newValue) => setDate(newValue)}
                            renderInput={(params) => <TextField {...params} />}
                          />
                        </LocalizationProvider>
                      </div>
                      <hr />
                      <h5>Client Details</h5>
                      <div className="col-lg-4">
                        <label className="form-label">Client Name</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Client Name"
                          name="clientName"
                          value={clientDetails.clientName}
                          onChange={handleClientDetailsChange}
                        />
                      </div>
                      <div className="col-lg-4">
                        <label className="form-label">Client Email</label>
                        <input
                          type="email"
                          className="form-control"
                          placeholder="Client Email"
                          name="clientEmail"
                          value={clientDetails.clientEmail}
                          onChange={handleClientDetailsChange}
                        />
                      </div>
                      <div className="col-lg-4">
                        <label className="form-label">Client Phone</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Client Phone"
                          name="clientPhone"
                          value={clientDetails.clientPhone}
                          onChange={handleClientDetailsChange}
                        />
                      </div>
                      <hr />
                      <h5>Product Pricing</h5>
                      <div className="row">
                        {products.map((item, index) => (
                          <div className="col-lg-12" key={index}>
                            <div className="row g-3 align-items-center">
                              <div className="col-lg-6">
                                <label className="form-label">Product</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Product"
                                  value={item.product}
                                  onChange={(e) =>
                                    handleProductChange(
                                      index,
                                      "product",
                                      e.target.value
                                    )
                                  }
                                />
                              </div>
                              <div className="col-lg-4">
                                <label className="form-label">Price $</label>
                                <input
                                  type="number"
                                  className="form-control"
                                  placeholder="Price"
                                  value={item.price}
                                  onChange={(e) =>
                                    handleProductChange(
                                      index,
                                      "price",
                                      e.target.value
                                    )
                                  }
                                />
                              </div>
                              <div className="col-lg-2 d-flex align-items-center">
                                <button
                                  type="button"
                                  className="btn btn-danger"
                                  onClick={() => handleRemoveProduct(index)}
                                  disabled={products.length === 1}
                                >
                                  Remove
                                </button>
                              </div>
                            </div>
                          </div>
                        ))}
                        <div className="col-lg-12 mt-3">
                          <button
                            type="button"
                            className="btn btn-primary"
                            onClick={handleAddProduct}
                          >
                            Add Product
                          </button>
                        </div>
                      </div>
                      <hr />
                      <div className="col-12">
                        <label className="form-label">Description / Note</label>
                        <textarea
                          className="form-control"
                          placeholder="Full description"
                          rows={2}
                          value={des}
                          onChange={(e) => setDes(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="card-header py-3 bg-transparent">
                    <div className="d-sm-flex align-items-center">
                      <h5 className="mb-2 mb-sm-0">Invoice generator</h5>
                      <div className="ms-auto">
                        <button type="submit" className="btn btn-primary">
                          Create Invoice
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </main>

        <div className="overlay nav-toggle-icon" />
        <a href="" className="back-to-top">
          <i className="bx bxs-up-arrow-alt" />
        </a>
      </div>
    </>
  );
};

export default InvoiceApp;
