import { Checkbox, TextField, FormControlLabel } from "@mui/material";

const HeadrailConfigEdit = ({ formData, handleChange }) => (
  <>
    <div className="col-12">
      <FormControlLabel
        control={
          <Checkbox
            name="sleeveOn" 
            checked={formData.sleeveOn?.isTrue || false} 
            onChange={(e) => {
              handleChange(e);
              if (e.target.checked) {
                handleChange({ target: { name: "sleeveOff", value: false } });
              }
            }}
            sx={{ color: "#fff" }}
          />
        }
        label={<span className="text-light">Sleeve On</span>}
      />
      {formData.sleeveOn?.isTrue && ( 
        <TextField
          type="number"
          label="Add-On Price"
          name="sleeveOnAddonPrice"
          value={formData.sleeveOn?.addonPrice} 
          onChange={handleChange}
          fullWidth
          style={{ marginTop: "10px", color: "#fff" }}
          InputLabelProps={{
            style: { color: "#fff" },
          }}
          inputProps={{
            style: { color: "#fff" },
          }}
        />
      )}
    </div>

    <div className="col-12">
      <FormControlLabel
        control={
          <Checkbox
            name="sleeveOff" 
            checked={formData.sleeveOff?.isTrue || false} 
            onChange={(e) => {
              handleChange(e);
              if (e.target.checked) {
                handleChange({ target: { name: "sleeveOn", value: false } });
              }
            }}
            sx={{ color: "#fff" }}
          />
        }
        label={<span className="text-light">Sleeve Off</span>}
      />
    </div>
  </>
);

export default HeadrailConfigEdit;
