import React, { useEffect, useState } from "react";
import { TextField, Chip, Box, Button, Snackbar, Alert } from "@mui/material";
import { Delete } from "@mui/icons-material";
import { useParams } from "react-router";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { API_URI } from "../../constants/GlobalConstants";
import { getProductTypesAction } from "../../redux/actions/ProductTypeactions";
import MatrixSkeletonLoader from "./MatrixSkeletonLoader";

const MatrixDetailsComp = () => {
  const { id } = useParams();
  const [matrixData, setMatrixData] = useState(null);
  const [tempWidthArray, setTempWidthArray] = useState([]);
  const [tempHeightArray, setTempHeightArray] = useState([]);
  const [matrix, setMatrix] = useState([]);
  const [dependencies, setDependencies] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [widthInput, setWidthInput] = useState(tempWidthArray.join(", "));
  const [heightInput, setHeightInput] = useState(tempHeightArray.join(", "));

  const dispatch = useDispatch();
  const userLogin = useSelector((state) => state.userSignIn);
  const { userInfo } = userLogin;
  const productTypesList = useSelector((state) => state.productTypes);
  const { productTypes } = productTypesList;

  useEffect(() => {
    dispatch(getProductTypesAction(userInfo.token));

    const fetchMatrixById = async () => {
      try {
        const response = await axios.get(`${API_URI}/api/matrices/${id}`, {
          headers: { Authorization: `Bearer ${userInfo.token}` },
        });
        const data = response.data.data;
        setMatrixData(data);
        setTempWidthArray(data.widthArray || []);
        setTempHeightArray(data.heightArray || []);
        setMatrix(data.matrix || []);
        setDependencies(data.productDependencies || []);
        setWidthInput(data.widthArray.join(", ") || "");
        setHeightInput(data.heightArray.join(", ") || "");
      } catch (error) {
        setErrorMessage("Failed to load matrix data.");
      }
    };

    fetchMatrixById();
  }, [dispatch, id, userInfo.token]);

  useEffect(() => {
    const newMatrix = tempWidthArray.map((_, colIndex) =>
      tempHeightArray.map((_, rowIndex) => matrix[colIndex]?.[rowIndex] || "")
    );
    setMatrix(newMatrix);
  }, [tempWidthArray, tempHeightArray]);

  const handleMatrixChange = (rowIndex, colIndex, value) => {
    const updatedMatrix = [...matrix];
    updatedMatrix[colIndex][rowIndex] = value;
    setMatrix(updatedMatrix);
  };

  const handleAddDependency = (dependency) => {
    if (!dependencies.includes(dependency)) {
      setDependencies([...dependencies, dependency]);
    }
  };

  const handleRemoveDependency = (dependency) => {
    setDependencies(dependencies.filter((dep) => dep !== dependency));
  };

  const handleUpdate = async () => {
    const updatedData = {
      productDependencies: dependencies,
      widthArray: tempWidthArray,
      heightArray: tempHeightArray,
      matrix,
    };

    try {
      await axios.put(`${API_URI}/api/matrices/${id}`, updatedData, {
        headers: { Authorization: `Bearer ${userInfo.token}` },
      });
      setSnackbarMessage("Matrix updated successfully!");
      setSnackbarSeverity("success");
      setSnackbarOpen(true);
    } catch (error) {
      setErrorMessage("Failed to update matrix.");
      setSnackbarMessage("Error updating matrix.");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  };

  const handleWidthInputChange = (e) => {
    const value = e.target.value;
    setWidthInput(value);

    const numbers = value
      .split(",")
      .map((num) => num.trim())
      .filter((num) => num === "" || !isNaN(Number(num)))
      .map((num) => (num === "" ? "" : Number(num)));
    setTempWidthArray(numbers);
  };

  const handleHeightInputChange = (e) => {
    const value = e.target.value;
    setHeightInput(value);

    const numbers = value
      .split(",")
      .map((num) => num.trim())
      .filter((num) => num === "" || !isNaN(Number(num)))
      .map((num) => (num === "" ? "" : Number(num)));
    setTempHeightArray(numbers);
  };

  const handleApplyChanges = () => {
    const widthArray = widthInput
      .split(",")
      .map((num) => Number(num.trim()))
      .filter((num) => !isNaN(num));

    const heightArray = heightInput
      .split(",")
      .map((num) => Number(num.trim()))
      .filter((num) => !isNaN(num));

    setTempWidthArray(widthArray);
    setTempHeightArray(heightArray);

    setSnackbarMessage("Width and Height arrays updated successfully!");
    setSnackbarOpen(true);
  };

  console.log(matrix);

  if (!matrixData) return <MatrixSkeletonLoader />;

  return (
    <div className="container mt-4">
      <h2>Edit Matrix</h2>

      {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}
      <div className="current-matrix mb-4">
        <h3>Current Matrix</h3>
        <div className="table-responsive">
          <table className="table table-bordered">
            <thead>
              <tr>
                <th rowSpan={2}>Height</th> {/* Y-axis title */}
                <th colSpan={matrixData.widthArray.length}>Width</th>{" "}
                {/* X-axis title */}
              </tr>
              <tr>
                {matrixData.widthArray.map((width, index) => (
                  <th key={index} className="fs-6">
                    {width}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {matrixData.heightArray.map((height, rowIndex) => (
                <tr key={rowIndex}>
                  <th className="fs-6 font-weight-bold">{height}</th>
                  {matrixData.widthArray.map((_, colIndex) => (
                    <td key={colIndex}>
                      {/* Accessing the transposed cell */}
                      {matrixData.matrix[colIndex] &&
                      matrixData.matrix[colIndex][rowIndex] !== undefined
                        ? matrixData.matrix[colIndex][rowIndex]
                        : ""}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      <div className="mb-3">
        <label>Product Dependencies</label>
        <TextField
          fullWidth
          variant="outlined"
          value=""
          InputProps={{
            startAdornment: (
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  gap: 1,
                  maxHeight: "75px",
                  overflowY: "auto",
                  color: "white",
                }}
              >
                {dependencies.map((dependency, index) => (
                  <Chip
                    key={index}
                    label={dependency}
                    onDelete={() => handleRemoveDependency(dependency)}
                    deleteIcon={<Delete sx={{ color: "white" }} />}
                    sx={{
                      backgroundColor: "transparent",
                      background: "red",
                      color: "white",
                    }}
                  />
                ))}
              </Box>
            ),
          }}
        />
        <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1, mt: 2 }}>
          {productTypes.map((type) => (
            <Chip
              key={type._id}
              label={type.name}
              onClick={() => handleAddDependency(type.name)}
              clickable
              sx={{
                bgcolor: dependencies.includes(type.name)
                  ? "secondary.main"
                  : "primary.light",
                color: dependencies.includes(type.name) ? "white" : "black",
              }}
            />
          ))}
        </Box>
      </div>

      <div className="row mb-3">
        <div className="col-md-5 mb-2">
          <label>Width Array</label>
          <TextField
            type="text"
            className="form-control"
            value={widthInput}
            onChange={handleWidthInputChange}
            sx={{
              "& .MuiInputBase-input": {
                color: "white",
              },
              "& .MuiOutlinedInput-root": {
                bgcolor: "rgba(255, 255, 255, 0.1)",
                "& fieldset": {
                  borderColor: "rgba(255, 255, 255, 0.5)",
                },
              },
            }}
          />
        </div>

        <div className="col-md-5 mb-2">
          <label>Height Array</label>
          <TextField
            type="text"
            className="form-control"
            value={heightInput} 
            onChange={handleHeightInputChange}
            sx={{
              "& .MuiInputBase-input": {
                color: "white",
              },
              "& .MuiOutlinedInput-root": {
                bgcolor: "rgba(255, 255, 255, 0.1)",
                "& fieldset": {
                  borderColor: "rgba(255, 255, 255, 0.5)",
                },
              },
            }}
          />
        </div>

        <div className="col-md-2 d-flex align-items-center justify-content-center">
          <Button
            variant="contained"
            color="primary"
            onClick={handleApplyChanges}
          >
            Apply Changes
          </Button>
        </div>
      </div>

      <div className="mb-3">
        <h4>Matrix</h4>
        <div className="table-responsive">
          <table className="table table-bordered">
            <thead>
              <tr>
                <th rowSpan={tempHeightArray.length}>Height</th>
                <th colSpan={tempWidthArray.length}>Width</th>
              </tr>
              <tr>
                {tempWidthArray.map((_, index) => (
                  <th key={index}>{tempWidthArray[index]}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {tempHeightArray.map((_, rowIndex) => (
                <tr key={rowIndex}>
                  <td>{tempHeightArray[rowIndex]}</td>
                  {tempWidthArray.map((_, colIndex) => (
                    <td key={colIndex}>
                      <TextField
                        variant="outlined"
                        fullWidth
                        value={
                          matrix[colIndex]
                            ? matrix[colIndex][rowIndex] || ""
                            : ""
                        }
                        onChange={(e) =>
                          handleMatrixChange(rowIndex, colIndex, e.target.value)
                        }
                        sx={{
                          "& .MuiInputBase-input": {
                            color: "white",
                          },
                          "& .MuiOutlinedInput-notchedOutline": {
                            borderColor: "white",
                          },
                          "&:hover .MuiOutlinedInput-notchedOutline": {
                            borderColor: "white",
                          },
                          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                            borderColor: "white",
                          },
                        }}
                      />
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <div className="d-flex flex-row">
        <Button variant="contained" color="secondary" onClick={handleUpdate}>
          Update Matrix
        </Button>
      </div>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
      >
        <Alert
          onClose={() => setSnackbarOpen(false)}
          severity={snackbarSeverity}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default MatrixDetailsComp;
