import React from "react";
import Header from "../../components/layout/Header";
import Sidebar from "../../components/layout/Sidebar";
import { Link } from "react-router-dom";
import MatrixInput from "../../components/matrixConfig/MatrixInput";
import MatrixListing from "../../components/matrixConfig/MatrixListing";
import MatrixDetailsComp from "../../components/matrixConfig/MatrixDetailsComp";

const VIewMatrix = ({ nav }) => {
  return (
    <div className={nav.isSidebar}>
      <Header nav={nav} />
      <Sidebar nav={nav} />
      <main className="page-content">
        <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
          <div className="breadcrumb-title pe-3">Matrix Management</div>
          <nav aria-label="breadcrumb" className="ps-3">
            <ol className="breadcrumb mb-0 p-0">
              <li className="breadcrumb-item">
                <Link to="/">
                  <i className="bx bx-home-alt" />
                </Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Matrix
              </li>
            </ol>
          </nav>
        </div>
        <hr />
        <div className="row">
          <div className="col-xl-12 mx-auto">
            <h6 className="mb-0">
              <i className="fadeIn animated bx bx-line-chart" />
              Matrix Details
            </h6>
            <hr />
          </div>
        </div>
        <MatrixDetailsComp />
      </main>
    </div>
  );
};

export default VIewMatrix;
