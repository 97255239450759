import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { TextField, Button, CircularProgress, Container, IconButton } from '@mui/material';
import axios from 'axios';
import { API_URI } from '../../constants/GlobalConstants';
import { AddCircle, RemoveCircle } from '@mui/icons-material';

const AppSettingsComp = () => {
  const userLogin = useSelector(state => state.userSignIn);
  const { userInfo } = userLogin;
  const jwttoken = userInfo.token;

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false); 
  const [invoiceSettings, setInvoiceSettings] = useState({
    companyName: '',
    street: '',
    city: '',
    province: '',
    postalCode: '',
    country: '',
    name: '',
    password:'',
    email: '',
    phone: '',
    GST_HST_number: '',
    taxRates: [{ type: '', value: '' }],
    logoUrl: '',
  });

  // Fetch existing settings when the component mounts
  useEffect(() => {
    const fetchSettings = async () => {
      try {
        setLoading(true);
        const { data } = await axios.get(`${API_URI}/api/app/application-settings`, {
          headers: { Authorization: `Bearer ${jwttoken}` }
        });
        
        if (data.settings) {
          setIsEditMode(true); 
          setInvoiceSettings({
            companyName: data.settings.companyName,
            street: data.settings.address.street,
            city: data.settings.address.city,
            province: data.settings.address.province,
            postalCode: data.settings.address.postalCode,
            country: data.settings.address.country,
            name: data.settings.contact.name,
            password:data.settings.contact.password,
            email: data.settings.contact.email,
            phone: data.settings.contact.phone,
            GST_HST_number: data.settings.GST_HST_number,
            taxRates: data.settings.taxRates || [{ type: '', value: '' }],
            logoUrl: data.settings.logoUrl,
          });
        }
        setLoading(false);
      } catch (err) {
        setLoading(false);
       
        if (err.response && err.response.status === 404) {
          setIsEditMode(false);
        } else {
          setError('Failed to fetch invoice settings');
        }
      }
    };

    fetchSettings();
  }, [jwttoken]);

 
  const handleInputChange = (e) => {
    setInvoiceSettings({
      ...invoiceSettings,
      [e.target.name]: e.target.value,
    });
  };

  
  const handleTaxChange = (index, field, value) => {
    const updatedTaxes = invoiceSettings.taxRates.map((tax, i) =>
      i === index ? { ...tax, [field]: value } : tax
    );
    setInvoiceSettings({ ...invoiceSettings, taxRates: updatedTaxes });
  };

 
  const addTaxField = () => {
    setInvoiceSettings({
      ...invoiceSettings,
      taxRates: [...invoiceSettings.taxRates, { type: '', value: '' }],
    });
  };


  const removeTaxField = (index) => {
    const updatedTaxes = invoiceSettings.taxRates.filter((_, i) => i !== index);
    setInvoiceSettings({ ...invoiceSettings, taxRates: updatedTaxes });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');
    setSuccess(false);
    
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${jwttoken}`,
        },
      };

      if (isEditMode) {
        // Update existing settings
        await axios.put(`${API_URI}/api/app/application-settings`, invoiceSettings, config);
        setSuccess(true);
      } else {
        // Create new settings
        await axios.post(`${API_URI}/api/app/application-settings`, invoiceSettings, config);
        setSuccess(true);
        setIsEditMode(true); 
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setError('Failed to save Application settings');
    }
  };

  const styles = {
    formContainer: {
      backgroundColor: '#ffffff',
      padding: '20px',
      borderRadius: '8px',
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    },
    input: {
      backgroundColor: '#f8f8f8',
      borderRadius: '4px',
    },
    taxContainer: {
      display: 'flex',
      alignItems: 'center',
      gap: '10px',
      marginBottom: '10px',
    },
  };
  
  return (
    <Container>
      <h2 className="my-4">App Settings</h2>

      {error && <div className="alert alert-danger">{error}</div>}
      {success && <div className="alert alert-success">Application settings {isEditMode ? 'updated' : 'added'} successfully!</div>}

      {loading ? (
        <CircularProgress />
      ) : (
        <form onSubmit={handleSubmit}  style={styles.formContainer}>
          <div className="row">
            <div className="col-md-6 mb-3">
              <TextField
                label="Company Name"
                name="companyName"
                value={invoiceSettings.companyName}
                onChange={handleInputChange}
                fullWidth
                required
              />
            </div>

            <div className="col-md-6 mb-3">
              <TextField
                label="Street"
                name="street"
                value={invoiceSettings.street}
                onChange={handleInputChange}
                fullWidth
                required
              />
            </div>

            <div className="col-md-6 mb-3">
              <TextField
                label="City"
                name="city"
                value={invoiceSettings.city}
                onChange={handleInputChange}
                fullWidth
                required
              />
            </div>

            <div className="col-md-6 mb-3">
              <TextField
                label="Province"
                name="province"
                value={invoiceSettings.province}
                onChange={handleInputChange}
                fullWidth
                required
              />
            </div>

            <div className="col-md-6 mb-3">
              <TextField
                label="Postal Code"
                name="postalCode"
                value={invoiceSettings.postalCode}
                onChange={handleInputChange}
                fullWidth
                required
              />
            </div>

            <div className="col-md-6 mb-3">
              <TextField
                label="Country"
                name="country"
                value={invoiceSettings.country}
                onChange={handleInputChange}
                fullWidth
                required
              />
            </div>

            <div className="col-md-6 mb-3">
              <TextField
                label="Contact Name"
                name="name"
                value={invoiceSettings.name}
                onChange={handleInputChange}
                fullWidth
                required
              />
            </div>
            <div className="col-md-6 mb-3">
              <TextField
                label="App password for email"
                name="password"
                onChange={handleInputChange}
                fullWidth
                
              />
            </div>

            <div className="col-md-6 mb-3">
              <TextField
                label="Email"
                name="email"
                value={invoiceSettings.email}
                onChange={handleInputChange}
                fullWidth
                required
              />
            </div>

            <div className="col-md-6 mb-3">
              <TextField
                label="Phone"
                name="phone"
                value={invoiceSettings.phone}
                onChange={handleInputChange}
                fullWidth
                required
              />
            </div>

            <div className="col-md-6 mb-3">
              <TextField
                label="GST/HST Number"
                name="GST_HST_number"
                value={invoiceSettings.GST_HST_number}
                onChange={handleInputChange}
                fullWidth
                required
              />
            </div>

            <div className="col-md-6 mb-3">
            <h6 className='text-primary'>Tax Rates</h6>
            {invoiceSettings.taxRates.map((tax, index) => (
                <div key={index} style={styles.taxContainer}>
                  <TextField
                    label="Tax Type (e.g., GST, HST)"
                    name="type"
                    value={tax.type}
                    onChange={(e) => handleTaxChange(index, 'type', e.target.value)}
                    style={styles.taxField}
                    required
                  />
                  <TextField
                    label="Tax Value (%)"
                    name="value"
                    type="number"
                    value={tax.value}
                    onChange={(e) => handleTaxChange(index, 'value', e.target.value)}
                    style={styles.taxField}
                    required
                  />
                   <IconButton onClick={() => addTaxField(index)} color="info">
                    <AddCircle />
                  </IconButton>
                  <IconButton onClick={() => removeTaxField(index)} color="error">
                    <RemoveCircle />
                  </IconButton>
                </div>
              ))}
            </div>

            <div className="col-md-6 mb-3">
              <TextField
                label="Logo URL"
                name="logoUrl"
                value={invoiceSettings.logoUrl}
                onChange={handleInputChange}
                fullWidth
              />
            </div>
          </div>

          <Button variant="contained" color="primary" type="submit" className="mt-4">
            {isEditMode ? 'Update Settings' : 'Add Settings'}
          </Button>
        </form>
      )}
    </Container>
  );
};


export default AppSettingsComp;
