import React, { useState, useEffect } from "react";
import Header from "../../components/layout/Header";
import Sidebar from "../../components/layout/Sidebar";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { createInvItemAction } from "../../redux/actions/InventoryAction";
import Loader from "../../components/layout/Loader";
import { ViewQRModel } from "../../components/inventoryManagement/ViewQRModel";
import QRCode from "qrcode";
import { toggleFullScreen } from "../../components/layout/functions/layout";
import {
  Box,
  Button,
  Step,
  StepButton,
  Stepper,
  Typography,
} from "@mui/material";

import PricingForm from "../../components/inventoryManagement/Stepper/PricingForm";
import CostingForm from "../../components/inventoryManagement/Stepper/CostingForm";
import DependancyForm from "../../components/inventoryManagement/Stepper/DependancyForm";
import StockDetailsForm from "../../components/inventoryManagement/Stepper/StockDetailsForm";
import ItemDetailsForm from "../../components/inventoryManagement/Stepper/ItemDetailsForm";

const AddInventoryItem = ({ nav }) => {
  const userLogin = useSelector((state) => state.userSignIn);
  const { error, userInfo } = userLogin;
  const newItem = useSelector((state) => state.createInvItemReducer);
  const { loading, success, data } = newItem;
  const costAndSellOptions = [
    { value: "foot", label: "Cost/Foot" },
    { value: "sqft", label: "Cost/SQFT" },
    { value: "unit", label: "Cost/Unit" },
  ];
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (!userInfo) {
      navigate("/sign-in");
    }
    window.scrollTo(0, 0);
    toggleFullScreen((nav = { nav }));
  }, [userInfo, navigate]);

  useEffect(() => {
    if (success && data) {
      generateQrCode(data.data._id);
    }
  }, [success]);

  const [formData, setFormData] = useState({
    name: "",
    category: "",
    des: "",
    inStock: null,
    stockAlert: null,
    unit: "",
    unitFactor: null,
    costValue: null,
    sellPrice: null,
    sellType: "",
    sleeveOn: false,
    sleeveOnAddonPrice: 0,
    sleeveOff: false,
    stockType: "",
    qtyPerStock: null,
    proDep: [],
    deductionMethod: "",
  });

  const [qrUrl, setQRUrl] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!isLastStep() && !allStepsCompleted()) {
      alert("Please complete all steps before submitting the form.");
      return;
    }

    const value = {
      ...formData,
      unitType: formData.unit,
      sellingPrice: formData.sellPrice,
      proDependency: formData.proDep,
    };
    await dispatch(createInvItemAction(value, userInfo.token));
    setFormData({
      name: "",
      category: "",
      des: "",
      inStock: 0,
      stockAlert: 0,
      unit: "",
      unitFactor: 0,
      costType: "",
      costValue: 0,
      sellType: "",
      sellPrice: 0,
      stockType: "",
      qtyPerStock: 0,
      sleeveOn: false,
      sleeveOnAddonPrice: 0,
      sleeveOff: false,
      proDep: [],
      deductionMethod: "",
    });
    setQRUrl("");
    navigate("/inventory/inventory-list");
  };

  const generateQrCode = async (id) => {
    try {
      const res = await QRCode.toDataURL(id);
      setQRUrl(res);
    } catch (error) {
      alert(error);
    }
  };

  const [activeStep, setActiveStep] = React.useState(0);
  const [completed, setCompleted] = React.useState({});
  const steps = [
    "Item Detail",
    "Stock Detail",
    "Costing",
    "Pricing",
    "ProDependency",
  ];
  const totalSteps = () => steps.length;
  const isLastStep = () => activeStep === totalSteps() - 1;

  const handleNext = () => {
    const newCompleted = { ...completed, [activeStep]: true };
    setCompleted(newCompleted);

    if (isLastStep() && allStepsCompleted()) {
      return; // Stay on the last step if all steps are completed
    }

    const newActiveStep = isLastStep()
      ? activeStep // Stay on the last step
      : activeStep + 1; // Otherwise, move to the next step

    setActiveStep(newActiveStep);
  };

  const handleBack = () =>
    setActiveStep((prevActiveStep) => prevActiveStep - 1);

  const handleStep = (step) => () => setActiveStep(step);


  const allStepsCompleted = () =>
    Object.keys(completed).length === totalSteps();

   // Form validation logic
   const validateStep = () => {
    switch (activeStep) {
      case 0:
        return formData.name && formData.category && formData.des;
      case 1:
        return formData.inStock > 0 && formData.stockAlert >0 && formData.unitFactor > 0 &&formData.unit && formData.qtyPerStock > 0 && formData.stockType ;
      case 2:
        return formData.costValue > 0;
      case 3:
        return formData.sellPrice > 0 && formData.sellType;
      case 4:
        return true; 
      default:
        return false;
    }
  };

  const renderFormContent = () => {
    switch (activeStep) {
      case 0:
        return (
          <ItemDetailsForm formData={formData} handleChange={handleChange} />
        );
      case 1:
        return (
          <StockDetailsForm formData={formData} handleChange={handleChange} />
        );
      case 2:
        return (
          <CostingForm
            formData={formData}
            handleChange={handleChange}
            costAndSellOptions={costAndSellOptions}
          />
        );
      case 3:
        return (
          <PricingForm
            formData={formData}
            handleChange={handleChange}
            costAndSellOptions={costAndSellOptions}
          />
        );
      case 4:
        return <DependancyForm formData={formData} setFormData={setFormData} />;
      default:
        return null;
    }
  };
console.log(formData);
  return (
    <>
      <div className={nav.isSidebar}>
        <Header nav={nav} />
        <Sidebar nav={nav} />
        <main className="page-content">
          <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
            <div className="breadcrumb-title pe-3">Inventory Management</div>
            <div className="ps-3">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb mb-0 p-1">
                  <li className="breadcrumb-item">
                    <Link to="/inventory/inventory-list">Inventory List</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Add Inventory Item
                  </li>
                </ol>
              </nav>
            </div>
          </div>
          <div className="container">
            <Box sx={{ width: "100%" }}>
              <Stepper activeStep={activeStep} alternativeLabel>
                {steps.map((label, index) => (
                  <Step key={label} completed={completed[index]}>
                    <StepButton color="inherit" onClick={handleStep(index)}>
                      <label style={{ color: "white" }}>{label}</label>
                    </StepButton>
                  </Step>
                ))}
              </Stepper>
              <div className="step-content">
                <Box sx={{ marginTop: 4 }}>{renderFormContent()}</Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    pt: 2,
                    gap: 2,
                  }}
                >
                  <Button
                    variant="outlined"
                    onClick={handleBack}
                    disabled={activeStep === 0}
                    sx={{ mr: 1 }}
                  >
                    Back
                  </Button>
                  {isLastStep() ? (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleSubmit}
                      disabled={!validateStep()}

                    >
                      Submit
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleNext}
                      disabled={!validateStep()}
                    >
                      {activeStep === steps.length - 1 ? "Finish" : "Next"}
                    </Button>
                  )}
                </Box>
                {loading ? (
                  <Loader />
                ) : (
                  <div className="row">
                    {qrUrl && ViewQRModel(qrUrl, setQRUrl)}
                  </div>
                )}
              </div>
            </Box>
          </div>
        </main>
      </div>
      {loading && <Loader />}
      {error && <div className="error-message">{error}</div>}
    </>
  );
};

export default AddInventoryItem;
